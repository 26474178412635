// import { sassTrue } from "sass";

export const DecisionParameters = [
  { value: "Price", checked: false },
  { value: "Experience", checked: false },
  { value: "Quality work", checked: false },
  { value: "Timeline", checked: false },
  { value: "Cost structure", checked: false },
];

export const RequiredFeatures = [
  { value: "", checked: false },
  { value: "Responsive Design", checked: false },
  { value: "Custom UI/UX Design", checked: false },
  { value: "Dark Mode Support", checked: false },
  { value: "Custom Animations", checked: false },
  { value: "Accessibility Compliance", checked: false },

  { value: "", checked: false },
  { value: "Static Website Development", checked: false },
  { value: "Dynamic Website Development", checked: false },
  { value: "CMS Integration (WordPress, Webflow, etc.)", checked: false },
  {
    value: "E-commerce Integration (Shopify, WooCommerce, etc.)",
    checked: false,
  },
  { value: "Api Integration", checked: false },
  { value: "Payment Gateway Integration", checked: false },
  { value: "User Authentication & Login System", checked: false },
  { value: "Admin Panel / Dashboard", checked: false },
  { value: "Contact Forms & Lead Capture", checked: false },
  { value: "Live Chat Integration", checked: false },
  { value: "Newsletter Subscription", checked: false },
  { value: "Push Notifications", checked: false },
  { value: "Custom Functionalities", checked: false },

  { value: "", checked: false },
  { value: "Fast Loading Speed", checked: false },
  { value: "Performance Optimization", checked: false },
  { value: "Security Features (SSL, Firewall, etc.)", checked: false },
  { value: "Backup & Restore", checked: false },
  { value: "Database Integration", checked: false },

  { value: "", checked: false },
  { value: "SEO Optimization (On-Page SEO, Schema, etc.)", checked: false },
  { value: "Blog Setup", checked: false },
  { value: "Social Media Integration", checked: false },
  { value: "Analytics & Tracking Setup", checked: false },
  { value: "Custom Landing Pages", checked: false },

  { value: "", checked: false },
  { value: "Stock Images & Icons", checked: false },
  { value: "Custom Graphics & Illustrations", checked: false },
  { value: "Copywriting for Website Page", checked: false },
  { value: "Other", checked: false },
];

export const TechPreference = [
  { value: "WordPress", checked: false },
  { value: "Shopify", checked: false },
  { value: "React.js", checked: false },
  { value: "Angular", checked: false },
  { value: "Wix", checked: false },
  { value: "Squarespace", checked: false },
  { value: "PHP/HTML/CSS", checked: false },
  { value: "No Preference", checked: false },
  { value: "Other", checked: false },
];
