import './summary2.css'

import Switch from './Switch';

import Payment_Button from './payment_button';

import pdf from "./assets/pdf.pdf"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from './store';


import React,{useEffect} from 'react';
import axios from 'axios';
import logo from './assets/bizowl_logo.png'
import useServicesStore from '../../Views/Services/storePr';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Pm_summary2=({data,index})=>{

    // const {servicesData, setServicesData} = useServicesStore();

    // console.log(servicesData[0])


    const sendWhatsAppMessageuser = async (phoneNumber,servicename,partnername, projectlink) => {
        try {
          console.log(phoneNumber);
          const response = await fetch("http://localhost:5000/api/whatsappmessage/send-message", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              
              phone: phoneNumber, // Pass the phone number directly
              params:[servicename,partnername, projectlink],
              message_name:"paymetn_confirm_client"
            }),
          });
      
          const data = await response.json();
          console.log("WhatsApp API Response:", data);
        } catch (error) {
          console.error("Error sending WhatsApp message:", error);
        }
      };
    
    const navigate = useNavigate();
    
    const { isChecked } = useStore();

    const[resData,setResData]=useState([]);
    // const key=process.env.RZR_KEY
    const createRazorpayOrder=async(amount)=>{
        let data=JSON.stringify({
            amount:amount * 100,
            currency:"INR"
        })
// 

        try{
            const response=await axios.post("https://backend-pr-tool.vercel.app/Orders",data,{
                headers:{
                  "Content-Type":"application/json"
                }
              })
              if (response.status !== 200) {
                throw new Error("Failed to create Razorpay order (HTTP error)");
              }
          
              return response.data;
        }catch(error){
            console.log(error);
        }
        
    }

    const handlePayment = async (e) => { 
        e.preventDefault(); // Prevent the default form submission
    
        const AMT = ((data[0]?.features?.price * 18) / 100 + data[0].features?.price);

        console.log(AMT)
    
        try {
            // Create Razorpay order and wait for it to complete
            const response = await createRazorpayOrder(AMT);
    
            // Check if the response is valid
            if (!response || !response.amount || !response.order_id) {
                console.error("Failed to create Razorpay order.");
                return;
            }
    
            var options = {
                "key": process.env.RZR_KEY, // Enter the Key ID generated from the Dashboard
                "key_secret": process.env.KEY_SECRET,
                "amount": response.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": response.currency,
                "name": "Bizowl", // Your business name
                "description": "Test Transaction",
                "image": logo,
                "order_id": response.order_id, 
                "handler": function (response) {
                    alert(response.razorpay_payment_id);
                },
                "prefill": {
                    "name": "Abc",
                    "email": "abc@example.com",
                    "contact": "9000090000"
                },
                "notes": {
                    "address": "Xyz"
                },
                "theme": {
                    "color": "#3399cc"
                }
            };
    
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
        } catch (error) {
            console.error("Error handling payment:", error);
        }
    };
    





    return(
        <div id='summary-container-2'>
            <div id='summary-heading-2'>
                <p>Payment summary</p>
            </div>

            <div className='summary-items-2'>
            <div className='summary-content-2'>
            <div className='summary-item1-2'><p>Total Payment</p></div>
            <div className='summary-item2-2'><p>₹{(((data[0].features?.price* 18)/100)+data[0].features?.price).toFixed(2)}/-</p></div> 
            </div>
            
            </div>
            <div id='total-2'>
            <div className='total-item1-2'><p>You'll pay</p></div>
            <div className='total-item2-2'><p id='amount-text-2'>₹{(((data[0].features?.price * 18)/100)+data[0].features?.price).toFixed(2)}/-</p><p id='GST-text-2'>( 18% GST included )</p></div>
            </div> 

            <div id='btn-container-2'>
            <button id='pay-button-2' 
            onClick={(e)=>{handlePayment(e)}}
            disabled={!isChecked} 
            
            style={{
                backgroundColor:isChecked?"":"gray",
                
            }}>Pay-Securely</button>
            </div>
            <br/>
           
            <div className='extra-details-2 cursor-pointer' id='last' 
            onClick={()=>navigate("/pr/terms&conditions")}>
            <div className='agreement-input-2'>
            {isChecked && <span className='checkmark ' style={{
                textAlign:"center",
                margin:"0 auto",
                color:"green",
                fontSize:""
            }}>✔</span>}
            </div>
            <div className='extra-text-2 ' ><p>I here by declare that all information provided above is 
            true, and I accept all the <a className='text-blue cursor-pointer' >terms and conditions</a></p>
             </div>

            </div>

        </div>
    );
}

export default Pm_summary2;