import React from "react";
import Star from "../../assets/solar_star-bold.png";
import ProfilePic from '../../assets/ProfilePic.png';
import { useMediaQuery } from "@mui/material";

const ReviewCard = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, #d9eafd 20%, white 100%)`,
        // backgroundColor:'#d9eafd',
        color: "#455A64",
      }}
      className="flex text-xs md:text-base flex-col gap-2 text-start w-[180px] md:w-[313px] p-3 pb-0 my-2 rounded-[1rem] overflow-hidden"
    >
      <p className="w-full">Shopify Development</p>
      {isMobile && (
        <div className="flex w-full items-center">
          {Array.from({ length: 5 }, (_, index) => (
            <img key={index} src={Star} width={"10px"} />
          ))}
          <b className="ml-2"> 5</b>
        </div>
      )}
      <p className="font-extrabold text-sm md:text-xl">
        Everything went really good with Bizowl team, The quality of work was
        great.
      </p>
      {!isMobile && (
        <div className="flex w-full items-center">
          {Array.from({ length: 5 }, (_, index) => (
            <img key={index} src={Star} width={"20px"} />
          ))}
          <b className="ml-2"> 5</b>
        </div>
      )}
      {!isMobile && (
        <p className="w-full text-end text-[5rem] leading-3 text-black font-bold">
          ”
        </p>
      )}
      <div className="w-full flex gap-1 font-bold py-2 border-t-2 border-white">
        <img src={ProfilePic} alt="profile-image" width={"25rem"} />
        <p>First Name</p>
      </div>
    </div>
  );
};

export default ReviewCard;
