import arrow from "./assets/arrow_circle.png";
import heroSection_Img from "./assets/herosection_img.png"
import search_icon from './assets/system_uicons_search.svg'
import { useMediaQuery } from "@mui/material";

const Herosection = () => {
  const isMobile=useMediaQuery('(max-width:650px)');
  const isSmallMobile=useMediaQuery('(max-width:400px)');
  const isTab=useMediaQuery("(max-width:1100px)");
  return (
    <div className="">
      <div className="font-cabin flex" style={{justifyContent:(isMobile||isTab)?"normal":"space-between",flexDirection:(isMobile||isTab)?"column":"row",padding:isMobile&&"20px"||"40px 40px 80px 80px"}}>
    
        {/* Left Div */}
        <div className="w-full lg:w-[550px] " style={{marginLeft:isMobile?"0px":"50px"}}>
          <h1 className="font-[550]" style={{fontSize:(isSmallMobile&&"22px")||(isMobile&&"25px")||"35px",width:isSmallMobile&&"340px"||isMobile&&"auto"||"540px"}}>
            Master the Art of{" "}
            <span className="text-blue-600">
              Entrepreneurship
              <img
                src={arrow}
                alt="arrow"
                className="inline mr-2"
                style={{height:(isSmallMobile&&"25px")||(isMobile&&"35px")||"40px",width:(isSmallMobile&&"25px")||(isMobile&&"35px")||"40px"}}
              />
              Solution to Every
            </span>
            &nbsp;Business Challenge
          </h1>
          <p className="font-[300] opacity-80" style={{
            fontFamily: "'Inter', sans-serif",
            fontSize:isMobile?"13px":"16px",
            marginTop:isMobile&&"10px"||"30px",
            marginBottom:isMobile&&"10px"||"30px",
            width:isMobile&&"300px"||"450px"
          }}>
            Drive into our extensive library of tutorials, eBooks and podcasts
            covering business mangement, marketing finance and more.
          </p>
          {/* Search  */}
          <div className="bg-white mt-3 flex justify-center items-center rounded-lg" style={{width:(isMobile||isTab)?"300px":"450px",paddingTop:"4px",paddingBottom:"4px",paddingLeft:"10px",marginBottom:(isMobile||isTab)?"10px":"30px"}}>
            <input
              className="w-full h-full  focus:outline-none rounded-lg"
              type="text"
              placeholder="Search for Services"
              style={{
            fontFamily: "'Inter', sans-serif",
            fontSize:(isMobile||isTab)?"12px":"16px",
            fontWeight:"100",
            padding:(isMobile||isTab)?"5px 0px 5px 10px":"5px 0px 5px 30px"
          }}/>
            <button className="flex justify-center items-center bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition-all duration-600 " style={{
            fontFamily: "'Inter', sans-serif",
            fontWeight:"100",
            height:(isMobile||isTab)?"35px":"50px",
            width:"250px",
            borderRadius:"6px",
            justifyContent:(isMobile||isTab)?"space-around":"space-between",
            marginRight:"15px",
            fontSize:(isMobile||isTab)?"12px":"18px"
          }}>
              Search
              <img src={search_icon} alt="search icon" style={{height:(isMobile||isTab)?"20px":"30px",width:(isMobile||isTab)?"20px":"30px"}} />
            </button>
          </div>
          <div className="mt-3 text-[12px] md:text-[14px]" style={{
            fontFamily: "'Inter', sans-serif",
            fontWeight:"400",
            
          }}>
           {/* Tags */}
           <div className="flex flex-wrap h-max w-[520px]" style={{width:isSmallMobile&&"300px"||isMobile&&"360px"||isTab&&"600px"||"510px"}}>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#7897BA]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Branding</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#FFC741]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Business Fundamentals</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#CF9DBB]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Marketing</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#A1D4C9]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Sales </p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white px-2 mb-4 text-[#FFC741]" style={{marginRight:isSmallMobile&&"5px"||"0px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Funding</p></div>

           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#CF9DBB]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Product</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#FFC741]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Market Research</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#7897BA]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Design</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#A1D4C9]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Operations</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white px-2 mb-4 text-[#FFC741]" style={{marginRight:isSmallMobile&&"5px"||"0px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>GTM</p></div>

           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#7897BA]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Finance</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#7897BA]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Advertising</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#CF9DBB]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>PMF</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#A1D4C9]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Business Model</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#A1D4C9]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>MVP</p></div>

           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#FECE58]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Pricing</p></div>
           <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white mr-5 px-2 mb-4 text-[#CF9DBB]" style={{marginRight:isMobile&&"5px"||"20px"}}><p className="p-1 px-8" style={{fontSize:isMobile&&"8px"||isTab&&"14px"||"10px"}}>Customer Analysis</p></div>
           </div>
           
          </div>
        </div>

        {/* Right Div */}
          <div className="flex h-max">
            <img src={heroSection_Img} alt="Hero Section Image" style={{maxWidth:"90%",height:"auto"}} />
          </div>
      </div>
    </div>
  );
};

export default Herosection;
