import React from "react";
import clockImg from "./assets/clock.png";
import { useNavigate } from "react-router-dom";

const Card = ({ img, btn, heading, description, category, id, type, time, tag, urlTitle }) => {
  const navigate = useNavigate();
  
  return (
    <div className="p-3 flex justify-center items-center mx-auto">
      <div className="w-[320px] h-[400px] md:w-[300px] md:h-[400px] xl:w-[280px] xl:h-[400px] 2xl:w-[300px] 2xl:h-[400px] 
          p-3.5 rounded-2xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 
          transform hover:scale-105">
        {/* Image Container */}
        <div className="w-full h-[140px] rounded-xl overflow-hidden">
          <img className="w-full h-full object-cover" src={img} alt="blogImg" />
        </div>

        {/* Category & Time */}
        <div className="flex items-center my-2">
          <button className="w-[80px] h-[42px] font-semibold text-xs bg-blue-200 p-2 text-blue-700 rounded-md">
            {category}
          </button>
          <img className="w-4 h-4 ml-3" src={clockImg} alt="clock" />
          <span className="text-gray-500 text-xs ml-1">{time}</span>
        </div>

        {/* Heading */}
        <h1 className="text-sm md:text-base font-bold line-clamp-3 ">{heading}</h1>

        {/* Description */}
        <p className="mt-2 text-xs md:text-sm font-medium text-gray-600 line-clamp-2">
          {description}
        </p>

        {/* Button */}
        <div className="absolute bottom-2.5 left-1/2 -translate-x-1/2">
    <button style={{borderRadius:" 6px"}}
      className="w-28 h-9  bg-blue-500 text-white text-sm font-medium hover:bg-blue-700 transition duration-300"
      onClick={type === "pdf" ? () => navigate(`/resources/market-research?id=${id}`) : () => navigate(`/resources/blogs/${tag}/${urlTitle}`)}
    >
      {btn}
    </button>
  </div>
      </div>
    </div>
  );
};

export default Card;
