import {useState,React, useContext} from "react";
import "./callScheduling.css";
import { primaryDB, partnerDB } from "../../config/firebase"; // Import Firestore instance
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getFirestore, query, where, getDocs } from "firebase/firestore";

import { userContext } from "../../App";
const CallScheduling = ({  setShowPopup , partnername,phone, name }) => {
    const [meetingDate, setMeetingDate] = useState("");
    const [meetingTime, setMeetingTime] = useState("");
    const [meetingType, setMeetingType] = useState("Quotation");
    const [description, setDescription] = useState("");
    const [showPopupit, setShowPopupit] = useState(false);
    const [partnerPhone, setPartnerPhone]=useState('');
    const [partnerName, setPartnerName] =useState('');
    const authcontext=useContext(userContext);
    console.log(authcontext);

    console.log(phone);
console.log(name);
    
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Format: YYYY-MM-DD
    };

    // Get current time in HH:MM format
    const getCurrentTime = () => {
        const now = new Date();
        return now.toTimeString().slice(0, 5); // Format: HH:MM
    };

    const fetchPartnerDetails = async (partnername) => {
        try {
          const q = query(collection(partnerDB, "userProfiles"), where("uid", "==", partnername));
          const querySnapshot = await getDocs(q);
      
          if (!querySnapshot.empty) {
            const partnerData = querySnapshot.docs[0].data(); // Assuming `uid` is unique
            console.log("Partner Details:", partnerData);
            const partnerphone=partnerData?.personal?.phone;
            const partnerN=partnerData?.personal?.firstName;
            setPartnerPhone(partnerphone);
            setPartnerName(partnerN);
            return partnerData;

          } else {
            console.log("No partner found with this name!");
            return null;
          }
        } catch (error) {
          console.error("Error fetching partner details:", error);
        }
      };
       // Replace with the actual partner name
      fetchPartnerDetails(partnername);

      const sendWhatsAppMessagepartner = async (phoneNumber,clientname, time, date ,partnername) => {
        try {
          console.log(phoneNumber);
          const response = await fetch("http://localhost:5000/api/whatsappmessage/send-message", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              
              phone: phoneNumber, // Pass the phone number directly
              params:[partnername, clientname, time, date],
              message_name:"meeting_scheduled_partner"
            }),
          });
      
          const data = await response.json();
          console.log("WhatsApp API Response:", data);
        } catch (error) {
          console.error("Error sending WhatsApp message:", error);
        }
      };



    const sendWhatsAppMessageuser = async (phoneNumber,name, time, date ,partnername) => {
        try {
          console.log(phoneNumber);
          const response = await fetch("http://localhost:5000/api/whatsappmessage/send-message", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              
              phone: phoneNumber, // Pass the phone number directly
              params:[name, time, date, partnername],
              message_name:"meeting_scheduled"
            }),
          });
      
          const data = await response.json();
          console.log("WhatsApp API Response:", data);
        } catch (error) {
          console.error("Error sending WhatsApp message:", error);
        }
      };
      
    const handleScheduleMeeting = async () => {
        if (!meetingDate || !meetingTime) {
            alert("Please select both Meeting Date and Meeting Time.");
            return;
        }
        const today = getTodayDate();
        if (meetingDate < today) {
            alert("Meeting date cannot be in the past.");
            return;
        }

        // Prevent selecting past time if the date is today
        if (meetingDate === today && meetingTime < getCurrentTime()) {
            alert("Meeting time cannot be in the past.");
            return;
        }
        try {

            const meetingId = `meet_${Date.now()}`;
            const participants = [
                authcontext.uid,  // Current user
                partnername, // Replace with actual partner UID
                "999999" // Replace with actual UID
            ];
    
            const response = await fetch("https://backend.bizzowl.com/api/videocall/agoratoken", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ channelName: meetingId, uids: participants }),
            });
            
    
            const data = await response.json();
            const agoraToken = data.tokens; // Store Agora token
            console.log("tokens",agoraToken);
            console.log(authcontext.uid,"hi");
            console.log(partnername);
            const collectionRef=collection(primaryDB,"videocallscheduling");
            await addDoc(collectionRef, {
                assignee: "Customer Name",
                appID:"9093b2022d5543a4bcee87eb0f362332",
                meetingId,
                meetingDate,
                meetingTime,
                meetingType,
                description,
                partnername,
                agoraToken,
                client: authcontext.uid,
                createdAt: serverTimestamp(), 
                dummyuserjoined:false,
            });
            //alert("Meeting Scheduled Successfully!");
            sendWhatsAppMessageuser(phone,name,meetingTime,meetingDate,partnerName);
            sendWhatsAppMessagepartner(partnerPhone, name, meetingTime, meetingDate, partnerName);
            setShowPopupit(true);
            //setShowPopup(false);
            setMeetingDate("");
            setMeetingTime("");
            setMeetingType("Quotation");
            setDescription("");
        } catch (error) {
            console.error("Error scheduling meeting:", error);
            alert("Failed to schedule meeting.");
        }
    };
    
    return (
        <div className="meeting-container">
            <div className="meeting-card">
                <h3>Meeting with CRB</h3>
                <div className="form-section">
                    {/* Assignee */}
                    <div className="form-group">
                        <label>Assignee</label>
                        <div className="assignee">
                            <span className="avatar">CN</span>
                            <span>Customer Name</span>
                        </div>
                    </div>

                    {/* Meeting Date */}
                    <div className="form-group">
                        <label>Meeting Date*</label>
                        <input type="date" className="date-input" value={meetingDate} onChange={(e) => setMeetingDate(e.target.value)}/>
                    </div>

                    {/* Meeting Time */}
                    <div className="form-group">
                        <label>Meeting Time*</label>
                        <input type="time" className="time-input"  value={meetingTime}
                            onChange={(e) => setMeetingTime(e.target.value)}/>
                    </div>

                    {/* Meeting Type */}
                    <div className="form-group">
                        <label>Meeting Type</label>
                      
                        <select value={meetingType} onChange={(e) => setMeetingType(e.target.value)}  >
                            <option>Quotation</option>
                            <option>Doubts</option>
                            <option>Other</option>
                        </select>
                       
                    </div>
                
                    {/* Description */}
                    <label>Description</label>
                    <textarea placeholder="Meeting Description" value={description}
                        onChange={(e) => setDescription(e.target.value)}></textarea>

                    {/* Attendees */}
                    <label style={{ fontSize: "1.25rem" }}>Attendees</label>
                    <div class="attendees">
                        <div class="attendee">
                            <span class="avatar">CN</span>
                            <div class="details">
                                <span>Customer Name</span>
                                <span class="note">(You)</span>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div class="attendee">
                            <span class="avatar">{partnerName.slice(0, 3).toUpperCase()}</span>
                            <div class="details">
                                <span>Partner</span>
                                <span class="note">({partnerName.slice(0, 3).toUpperCase()})</span>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div class="attendee">
                            <span class="avatar">CN</span>
                            <div class="details">
                                <span>Team Bizowl</span>
                                <span class="note">(Growth Partner)</span>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="button-section">
                        <button className="cancel-button" onClick={() => setShowPopup(false)}
                        >
                            Cancel
                        </button>
                        <button className="schedule-button" onClick={handleScheduleMeeting} >Schedule Meeting</button>
                    </div>
                </div>
            </div>
            {showPopupit && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="check-icon">✔️</div>
                        <h2>Meeting has been created</h2>
                        <p>Your meeting has been scheduled successfully. Check details in the dashboard. Your meeting has been scheduled successfully. Check details in the dashboard.</p>
                        <div className="modal-buttons">
                            <button className="cancel-button" onClick={() => setShowPopup(false)}>Cancel</button>
                            <button className="details-button">Meeting Details</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CallScheduling;
