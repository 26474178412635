import React, { useState } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { primaryAuth } from "../../config/firebase";
import { Helmet } from "react-helmet";

const OtpTest = () => {  
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  const sendOtp = async () => {
    if (phoneNumber.length !== 10) {
      alert("Invalid phone number");
      return;
    }

    try {
      const formattedPhone = `+91${phoneNumber}`;

      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            callback: (response) => {
              console.log("Recaptcha resolved");
            },
          },
          primaryAuth
        );
      }

      const confirmation = await signInWithPhoneNumber(
        primaryAuth,
        formattedPhone,
        window.recaptchaVerifier
      );
      setConfirmationResult(confirmation);
      alert("OTP sent");
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      alert("OTP failed: " + error.message);
    }
  };

  const verifyOtp = async () => {
    if (!otp || !confirmationResult) {
      alert("Enter OTP");
      return;
    }
    try {
      await confirmationResult.confirm(otp);
      alert("Phone verified");
    } catch (error) {
      alert("Invalid OTP");
    }
  };

  return (
    <div>
      <Helmet>
          <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <input
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Phone number"
      />
      <button onClick={sendOtp}>Send OTP</button>

      <input
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        placeholder="Enter OTP"
      />
      <button onClick={verifyOtp}>Verify OTP</button>

      <div id="recaptcha-container"></div>
    </div>
  );
};

export default OtpTest;
