import './booking_container.css'

const WebBooking_Container=({biddingData,handleFormData,index})=>{
    const letterArray=biddingData?.partnerDetails?.partnerName.substring(0,3).toUpperCase().split("")
    console.log(letterArray)
    return(
        <div className='booking-container'>
            <div className='booking-title'>
                <div className="heading-content">
                    <div id='logo-border1'>
                        <div id="logo-border2"><p>{letterArray?letterArray[0]:""}<span>{letterArray?letterArray[1]:""}</span>{letterArray?letterArray[2]:""}</p></div>
                    </div>
                    <p>Booking Details</p> 
                </div>
            </div>
            <div className='booking-content'>

                <div className='booking-inputs' id='top-input'>
                    <input name="fullName" type="text" className='input-box margin-left' placeholder='Full Name' onChange={handleFormData}/>
                    <input name="contactNumber" type="text" className='input-box margin-right' placeholder='Contact Number' onChange={handleFormData}/>
                </div>
                <div className='booking-inputs'>
                    <input name="email" type="text" className='input-box margin-left' placeholder='Email Address' onChange={handleFormData}/>
                    <input name="whatsappNo" type="text" className='input-box margin-right' placeholder='Whatsapp Number' onChange={handleFormData}/>

                </div>
                <div className='booking-inputs'>
                    <input name="invoiceName" type="text" className='input-box margin-left' placeholder='Name on Invoice' onChange={handleFormData}/>
                    <input name="GSTNumber" type="text" className='input-box margin-right' placeholder='GST number' onChange={handleFormData}/>
                </div> 
                <div className='comment-area'>
                <input name="address" type="text" className='text-area' placeholder='Full Address' onChange={handleFormData}/>
                </div>
            </div>
        </div>
    );

}

export default WebBooking_Container;