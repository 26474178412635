import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const VideoCard = ({ h1, h3, videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="p-3 flex justify-center items-center mx-auto">
      <div className="w-[320px] h-[400px] md:w-[300px] md:h-[400px] xl:w-[280px] xl:h-[400px] 2xl:w-[300px] 2xl:h-[440px] 
          p-2 rounded-2xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 
          transform hover:scale-105 relative">
        
        {/* Video or Thumbnail */}
        <div className="w-full h-[240px] rounded-xl overflow-hidden relative">
          {isPlaying ? (
            <div className="relative w-full h-full">
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              {/* Close Button */}
              <button
                onClick={() => setIsPlaying(false)}
                className="absolute top-2 right-2 bg-black bg-opacity-60 text-white p-1 rounded-full text-sm hover:bg-opacity-90 transition"
              >
                <AiOutlineClose />
              </button>
            </div>
          ) : (
            <img
              src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
              alt="YouTube thumbnail"
              className="w-full h-full object-cover cursor-pointer"
              onClick={() => setIsPlaying(true)}
            />
          )}
        </div>

        {/* Title & Description */}
        <h1 className="mt-4 text-2xl md:text-base font-bold line-clamp-2 px-2 ">{h1}</h1>
        <h3 className="mt-1 text-xs md:text-sm font-medium text-gray-600 px-2 line-clamp-2">
          {h3}
        </h3>

        {/* Button */}
        {!isPlaying && (
           <div className="absolute bottom-2.5 left-1/2 -translate-x-1/2">
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
