import React, { useEffect, useState,useContext } from "react";
import { FaBars } from "react-icons/fa";
import "../../Styles/CompStyle/NavBarStyle.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { setIsVisible } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
// import { LandingPagePopup } from "../Design/fixedDesigns";
import { WindowOpenLink } from "../Extra/extraFunction";
import { Offcanvas } from 'bootstrap';
import { motion,AnimatePresence } from "framer-motion";
import {FiChevronDown} from "react-icons/fi"
import { useMediaQuery } from "@mui/material";
import { userContext } from "../../App";

const NavBar = () => {
  const dispatch = useDispatch();
  const [isActiveHoroscope, setIsActiveHoroscope] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [open, setOpen] = useState(false);
  const [isSupportDropdownOpen, setIsSupportDropdownOpen] = useState(false);
  const [showExpertsDropdown, setShowExpertsDropdown]=useState(false);
  const { isVisible, isClosed, isSubmit } = useSelector(
    (state) => state.landingPageReducer
  );
  const User=useContext(userContext);

  useEffect(() => {
    setTimeout(() => {
      if (!isClosed && !isSubmit) dispatch(setIsVisible(true));
    }, 30000); // 30 Sec
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleSupportDropdown = () => {
    setIsSupportDropdownOpen(!isSupportDropdownOpen);
  };
  const toggleExpertsDropdown = () => {
    setShowExpertsDropdown(!showExpertsDropdown);
  };

  const toggleDropdown = (dropdown) => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown)); // toggle dropdown or close if the same one is clicked
  };

  const isMobile = useMediaQuery("(max-width:768px)");



  const styles = {
    dropdownStyles: {
      position: "absolute",
      top: "120%", // Positions dropdown below "Support"
      left: "auto",
      right: "120px",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: "250px",
      padding: "12px",
      borderRadius: "4px",
      zIndex: 10,
      margin: "3px",
    },
    expertsDropdownStyles: {
      position: "absolute",
      top: "120%", // Adjust position based on "Talk to Experts" icon
      right: "12px",
      left:"auto", // Align with "Talk to Experts" icon
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: "280px",
      padding: "12px",
      borderRadius: "4px",
      zIndex: 10,
    },
    hr: {
      margin: "10px 0",
    },
    item: {
      display: "flex",
      alignItems: "center",
      padding: "5px 0",
    },
    navLink: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  };

  // Variants for framer-motion
  const dropdownVariants = {
    open: {
      opacity: 1,
      scaleY: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    closed: {
      opacity: 0,
      scaleY: 0,
      transition: {
        when: "afterChildren",
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
    },
    closed: {
      opacity: 0,
      y: -15,
    },
  };


  var hyperMainMenuHeadStackList = [
    {
      name: "Business Fundamental Services",
      image: "Images/services/Other/StartupConsulting.png",
    },
    {
      name: "WEBSITE DEVELOPMENT",
      image: "Images/services/Graphic/WebsiteDesign.png",
    },
    {
      name: "DIGITAL MARKETING",
      image: "/Images/services/DigitalMarketing/SocialMediaMarketing.png",
    },
    {
      name: "GRAPHIC DESIGN SERVICE",
      image: "/Images/services/Graphic/WebsiteDesign.png",
    },
    // {
    //     name: 'MEDIA PRODUCTION',
    //     image: '/Images/services/MediaProduction/SocialMediaVideos.png'
    // },
    {
      name: "MARKETING",
      image: "Images/services/Marketing/ContentMarketing.png",
    },
    {
      name: "ADVERTISING",
      image: "Images/services/DigitalMarketing/SocialMediaAdvertising.png",
    },
  ];

  var hyperSubMenuStackList = [
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      name: "Business Fundamental Services",
      image: "Images/services/Other/StartupConsulting.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      name: "Website Development",
      image: "Images/services/Graphic/WebsiteDesign.png",
    },
    {
      head: "DIGITAL MARKETING",
      name: "Search",
      image: "Images/services/DigitalMarketing/EComSEO.png",
    },
    {
      head: "DIGITAL MARKETING",
      name: "Social",
      image: "Images/services/DigitalMarketing/SocialMediaAdvertising.png",
    },
    {
      head: "DIGITAL MARKETING",
      name: "Methods & Techniques",
      image: "Images/services/DigitalMarketing/Funnelbuilding.png",
    },
    {
      head: "DIGITAL MARKETING",
      name: "Analytics & Strategy",
      image: "Images/services/DigitalMarketing/WebAnalytics1.png",
    },
    {
      head: "DIGITAL MARKETING",
      name: "Industry & Purpose-Specific",
      image: "Images/services/DigitalMarketing/MusicPromotion.png",
    },
    {
      head: "DIGITAL MARKETING",
      name: "Writing",
      image: "Images/services/DigitalMarketing/CreativeWriting.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      name: "Logo & Brand Identity",
      image: "Images/services/Graphic/LogoDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      name: "Web & App Design",
      image: "Images/services/Graphic/AppDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      name: "Marketing Design",
      image: "Images/services/Other/MarketResearch.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      name: "Packaging & Covers",
      image: "Images/services/Graphic/packingAndLabelDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      name: "Print Design",
      image: "Images/services/Graphic/CatalogueDesign.png",
    },
    // {
    //   head: "MEDIA PRODUCTION",
    //   name: "Video",
    //   image: "Images/services/DigitalMarketing/VideoMarketing.png",
    // },
    // {
    //   head: "MEDIA PRODUCTION",
    //   name: "Still",
    //   image: "Images/services/MediaProduction/CorporateShoots.png",
    // },
    // {
    //   head: "MEDIA PRODUCTION",
    //   name: "Audio",
    //   image: "Images/services/MediaProduction/AudioLogo.png",
    // },
    {
      head: "MARKETING",
      name: "Marketing",
      image: "Images/services/Marketing/ContentMarketing.png",
    },
    {
      head: "ADVERTISING",
      name: "Advertising",
      image: "Images/services/DigitalMarketing/SocialMediaAdvertising.png",
    },
  ];

  var hyperMenuStackList = [
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Logo & Brand Identity",
      name: "Visual Design",
      image: "Images/services/Graphic/visualDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Logo & Brand Identity",
      name: "Logo Design",
      image: "Images/services/Graphic/LogoDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Logo & Brand Identity",
      name: "Website Design",
      image: "Images/services/Graphic/WebsiteDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Logo & Brand Identity",
      name: "Brand Style Guides",
      image: "Images/services/Graphic/BrandStyleGuide.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Logo & Brand Identity",
      name: "Fonts & Typography",
      image: "Images/services/Graphic/FontStyleGuide.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Logo & Brand Identity",
      name: "Business Cards & Stationery",
      image: "Images/services/Graphic/BusinessCardsAndStationary.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Logo & Brand Identity",
      name: "Brand Identity",
      image: "Images/services/Graphic/BrandIdentity.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Web & App Design",
      name: "Website design",
      image: "Images/services/Graphic/WebsiteDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Web & App Design",
      name: "App Design",
      image: "Images/services/Graphic/AppDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Web & App Design",
      name: "UX Design",
      image: "Images/services/Graphic/UXDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Web & App Design",
      name: "Landing Page Design",
      image: "Images/services/Graphic/LandingPageDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Web & App Design",
      name: "Icon Design",
      image: "Images/services/Graphic/IconDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Marketing Design",
      name: "Social Media Creatives Design",
      image: "Images/services/Graphic/SocialMediaCreatives.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Marketing Design",
      name: "Email Design",
      image: "Images/services/Graphic/EmailDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Marketing Design",
      name: "Web Banners",
      image: "Images/services/Graphic/WebBanners.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Packaging & Covers",
      name: "Packaging & Label Design",
      image: "Images/services/Graphic/packingAndLabelDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Packaging & Covers",
      name: "Image Editing",
      image: "Images/services/Graphic/ImageEditing.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Packaging & Covers",
      name: "Infographic Design",
      image: "Images/services/Graphic/InfographicDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Packaging & Covers",
      name: "T-Shirts & Merchandise",
      image: "Images/services/Graphic/MerchandiseDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Print Design",
      name: "Flyer Design",
      image: "Images/services/Graphic/FlyerDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Print Design",
      name: "Brochure Design",
      image: "Images/services/Graphic/BrochureDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Print Design",
      name: "Poster Design",
      image: "Images/services/Graphic/PosterDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Print Design",
      name: "Catalog Design",
      image: "Images/services/Graphic/CatalogueDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Print Design",
      name: "Menu Design",
      image: "Images/services/Graphic/MenuDesign.png",
    },
    {
      head: "GRAPHIC DESIGN SERVICE",
      mainMenu: "Print Design",
      name: "Invitation Design",
      image: "Images/services/Graphic/InvitationDesign.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Search",
      name: "Search Engine Optimization (SEO)",
      image: "Images/services/DigitalMarketing/SEO.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Search",
      name: "Search Engine Marketing (SEM)",
      image: "Images/services/DigitalMarketing/SEM.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Search",
      name: "Local SEO",
      image: "Images/services/DigitalMarketing/LocalSEO.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Search",
      name: "E-Commerce SEO",
      image: "Images/services/DigitalMarketing/EComSEO.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Search",
      name: "Video SEO",
      image: "Images/services/DigitalMarketing/VideoSEO.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Social",
      name: "Social Media Marketing",
      image: "Images/services/DigitalMarketing/SocialMediaMarketing.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Social",
      name: "Social Media Advertising",
      image: "Images/services/DigitalMarketing/SocialMediaAdvertising.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Social",
      name: "Social Media Management",
      image: "Images/services/DigitalMarketing/SocialMediaManagement.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Social",
      name: "PPC ( Pay Per Click )",
      image: "Images/services/Advertising/PPC.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Methods & Techniques",
      name: "Video Marketing",
      image: "Images/services/DigitalMarketing/VideoMarketing.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Methods & Techniques",
      name: "E-Commerce Marketing",
      image: "Images/services/MediaProduction/ECommerceProduct.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Methods & Techniques",
      name: "Email Marketing",
      image: "Images/services/DigitalMarketing/EmilMarketing.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Methods & Techniques",
      name: "Text Message Marketing",
      image: "Images/services/DigitalMarketing/SMSMarketing.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Methods & Techniques",
      name: "Funnel Building",
      image: "Images/services/DigitalMarketing/Funnelbuilding.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Methods & Techniques",
      name: "Link building",
      image: "Images/services/DigitalMarketing/LinkBuilding.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Analytics & Strategy",
      name: "Marketing Strategy",
      image: "Images/services/DigitalMarketing/MarketingStrategy.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Analytics & Strategy",
      name: "Web Analytics",
      image: "Images/services/DigitalMarketing/WebAnalytics1.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Industry & Purpose-Specific",
      name: "Music Promotion",
      image: "Images/services/DigitalMarketing/MusicPromotion.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Industry & Purpose-Specific",
      name: "Book & eBook Marketing",
      image: "Images/services/DigitalMarketing/BookMarketing.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Industry & Purpose-Specific",
      name: "Mobile App Marketing",
      image: "Images/services/DigitalMarketing/MobileAppMarketing.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Articles & Blog Posts",
      image: "Images/services/DigitalMarketing/ArticleLogPost.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Ad Copy",
      image: "Images/services/DigitalMarketing/Adcopy.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Social Media Copy",
      image: "Images/services/DigitalMarketing/SocialMediaMarketing.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Email Copy",
      image: "Images/services/DigitalMarketing/Funnelbuilding.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Case Studies",
      image: "Images/services/DigitalMarketing/CaseStudy.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Creative Writing",
      image: "Images/services/DigitalMarketing/CreativeWriting.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Technical Writing",
      image: "Images/services/DigitalMarketing/TechnicalWriting.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Website Content",
      image: "Images/services/DigitalMarketing/WebsiteContent.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Product Descriptions",
      image: "Images/services/DigitalMarketing/ProductDescriptions.png",
    },
    {
      head: "DIGITAL MARKETING",
      mainMenu: "Writing",
      name: "Press Releases",
      image: "Images/services/DigitalMarketing/PressReleaseService.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "Corporate Videos",
      image: "Images/services/MediaProduction/CorporateVideos.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "Ad Film Shoot",
      image: "Images/services/MediaProduction/AdFilmShoot.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "Video Editing",
      image: "Images/services/MediaProduction/VideoEditing.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "Animated Explainers",
      image: "Images/services/MediaProduction/AnimatedExplainers.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "Logo Animation",
      image: "Images/services/MediaProduction/logoAnimation.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "Intro & Outro Videos",
      image: "Images/services/MediaProduction/IntroOutroVideo.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "App & Website Previews",
      image: "Images/services/MediaProduction/AppWebPreview.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "Social Media Videos",
      image: "Images/services/MediaProduction/SocialMediaVideos.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Video",
      name: "E-Commerce Product Videos",
      image: "Images/services/MediaProduction/ECommerceProduct.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Still",
      name: "Product Photography",
      image: "Images/services/MediaProduction/ProductPhotography.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Still",
      name: "Commercial Shoots",
      image: "Images/services/MediaProduction/CommercialShoot.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Still",
      name: "Corporate Shoots",
      image: "Images/services/MediaProduction/CorporateShoots.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Audio",
      name: "Voice Over",
      image: "Images/services/MediaProduction/VoiceOver.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Audio",
      name: "Audio Ads Production",
      image: "Images/services/MediaProduction/AudioAds.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Audio",
      name: "Audio Editing",
      image: "Images/services/MediaProduction/AudioEditing.png",
    },
    {
      head: "MEDIA PRODUCTION",
      mainMenu: "Audio",
      name: "Audio Logo & Sonic Branding",
      image: "Images/services/MediaProduction/AudioLogo.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Website Development",
      image: "Images/services/WebDevelopment/WebDevelopment.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Website Maintenance",
      image: "Images/services/WebDevelopment/WebSiteMaintenance.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Business Websites",
      image: "Images/services/Graphic/WebsiteDesign.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "E-Commerce Development",
      image: "Images/services/WebDevelopment/EComDevelopment.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Blog Website",
      image: "Images/services/WebDevelopment/BlogWebsite.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "WordPress Websites",
      image: "Images/services/WebDevelopment/WebDevelopment.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Simple Static Website",
      image: "Images/services/WebDevelopment/SimpleStaticWebsite.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Woo Commerce",
      image: "Images/services/WebDevelopment/WooCommerce.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Shopify",
      image: "Images/services/WebDevelopment/ShopifyDevelopment.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Wix",
      image: "Images/services/WebDevelopment/Wix.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Custom Websites",
      image: "Images/services/WebDevelopment/CustomSite.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Application Development",
      image: "Images/services/WebDevelopment/AppDevelopment.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Game Development",
      image: "Images/services/WebDevelopment/GameDevelopment.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Al Applications",
      image: "Images/services/WebDevelopment/AIApplication.png",
    },
    {
      head: "WEBSITE DEVELOPMENT",
      mainMenu: "Website Development",
      name: "Support & IT",
      image: "Images/services/WebDevelopment/ItSupport.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "Billboard Advertising",
      image: "Images/services/Advertising/BillboardAdvt.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "TV ads",
      image: "Images/services/Advertising/TvAds.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "YouTube Advertisement",
      image: "Images/services/Advertising/YoutubeAdvertsing.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "Metro Advertising",
      image: "Images/services/Advertising/MetroAdvertisement.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "FM Radio ads",
      image: "Images/services/Advertising/FmRadioAds.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "Audio Ads",
      image: "Images/services/MediaProduction/AudioAds.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "PPC ( Pay Per Click ) Advertising",
      image: "Images/services/Advertising/PPC.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "Newspaper & Magazine Ads",
      image: "Images/services/Advertising/NewspaperAds.png",
    },
    {
      head: "ADVERTISING",
      mainMenu: "Advertising",
      name: "Vehicle Wraps & Bus Signage",
      image: "Images/services/Advertising/VehicleWraps.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Content Marketing",
      image: "Images/services/Marketing/ContentMarketing.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Influencer Marketing",
      image: "Images/services/Marketing/InfluencerAdvt.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Inbound Marketing",
      image: "Images/services/Marketing/InboundMarketing.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Outbound Marketing",
      image: "Images/services/Marketing/OutboundMArketing.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "B2B Marketing",
      image: "Images/services/Marketing/B2BMarketing.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Public Relations",
      image: "Images/services/Other/SalesCustomerCare.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Paid Media",
      image: "Images/services/Marketing/PaidMedia.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Offline Marketing",
      image: "Images/services/Marketing/OfflineMarketing.png",
    },
    {
      head: "MARKETING",
      mainMenu: "Marketing",
      name: "Affiliate Marketing",
      image: "Images/services/Marketing/AffiliateMarketing.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Market Research",
      image: "Images/services/Other/MarketResearch.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Idea Validation",
      image: "Images/services/BusinessFundamental/IdeaValidation.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Business Model Development",
      image: "Images/services/BusinessFundamental/BusinessModelDevelopment.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Target Audience Analysis",
      image: "Images/services/BusinessFundamental/TargetAudienceAnalsyis.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Market Entry Strategy",
      image: "Images/services/BusinessFundamental/MarketEntry.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Financial Planning",
      image: "Images/services/BusinessFundamental/financial-report.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Startup Consulting",
      image: "Images/services/Other/StartupConsulting.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Business Canvas Model",
      image: "Images/services/BusinessFundamental/BusinessCanvasModel.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Market Size Analysis",
      image: "Images/services/BusinessFundamental/MarketSizeAnalysis.png",
    },
    {
      head: "BUSINESS FUNDAMENTAL SERVICES",
      mainMenu: "Business Fundamental Services",
      name: "Competitor Analysis",
      image: "Images/services/BusinessFundamental/CompetitorsAnalysis.png",
    },
  ];

  

  const [hyperMainMenuHeadStack, setHyperMainMenuHeadStack] = useState(
    hyperMainMenuHeadStackList
  );
  const [hyperSubMenuStack, setHyperSubMenuStack] = useState(
    hyperSubMenuStackList
  );
  const [hyperMenuStack, setHyperMenuStack] = useState(hyperMenuStackList);

  const [hyperMainMenuHeadStackValue, setHyperMainMenuHeadStackValue] =
    useState("Business Fundamental Services");
  const [hyperSubMenuStackValue, setHyperSubMenuStackValue] = useState(
    "Business Fundamental Services"
  );
  const [hyperMenuStackValue, setHyperMenuStackValue] = useState("");

  const hyperMenuStackValueFunction = (val) => {
    setHyperMenuStackValue(val);
    WindowOpenLink("/projectCreation", "_self");
  };
  const navigate = useNavigate();



  return (
    <>
      <nav className="navbar paddingPhone0 flexPhoneLeft fixedStyleForMobile bg-body-tertiary navbar-light navBarBGLight ">
        <div className="menuButton-mobile">
          {/* <FaBars
            className="barStyle"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          /> */}
          <img src="/Images/marketeq_menu_new.png" alt="hamburger_icon"
          className="barStyle"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasDarkNavbar"
          aria-controls="offcanvasDarkNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ width: "50px", height: "auto",marginTop:"6px"}}/>
        </div>

        <NavLink className="navbar-brand" to="/">
          <img
            alt=""
            src="/Images/logos/logo-of-BIZOWL--Business-Services.png"
            className="webSection headerImageStyle border-0 bg-transparent"
          />

         
        </NavLink>
       
        <NavLink className="navbar-brand" to="/">
     
          <img
            alt=""
            src="/Images/logos/logo-of-BIZOWL--Business-Services.png"
            className="mobileSection headerImageStyle border-0 bg-transparent "
          />
        </NavLink>


        {isMobile && 
         <div className="top-right-icons d-flex align-items-center justify-content-end mr-[20px] ml-auto">
         <img
           src="/Images/expertsnew1.png" 
           alt="talkToExpertsIcon"
           className="me-3"
           style={{ width: '55px', height: 'auto' }}
           onClick={toggleModal}
         />
               {showModal && (
           <div className="modal  d-flex items-end justify-center " tabIndex="-1" role="dialog">
             <div className="modal-dialog modal-lg  w-full " role="document">
             <div className="modal-content  rounded-t-[60px] overflow-hidden">
                 <div className="modal-header bg-[#D7E9FD]  rounded-t-full ">
                 
                   <h5 className="modal-title text-black pl-3 mx-1">Talk to Expert</h5>
                   <button type="button" className="btn btn-close mx-1"  onClick={toggleModal} aria-label="Close">
                     {/* <span aria-hidden="true">&times;</span> */}
                   </button>
                 </div>
                 <div className="modal-body text-black ">
                   <div className="d-flex align-items-center  mb-3 ">
                     <img
                       src="/Images/agentIcon.png"
                       alt="agent icon"
                       style={{ width: '30px', marginRight: '30px' }}
                     />
                     <div>
                       <p className="mb-0 text-gray-500">Help Regarding Service & Support</p>
                       <p className="mb-0">
                       <a href="tel:+919354651433" style={{ color: 'inherit', textDecoration: 'none' }}>
                         +91 9354651433 
                         </a>
                         </p>
                        
                     </div>
                   </div>
                   <hr className="w-full"/>
                   <div className="flex items-center  mt-2">
                     <img
                       src="/Images/carbon_email-new.png"
                       alt="email icon"
                       style={{ width: '30px', marginRight: '30px' }}
                     />
                     <div>
                       <p className="mb-0 text-gray-500 ">Query Related to Startup?</p>
                       <p className="mb-0 " onClick={() => {
                   WindowOpenLink("mailto:contact@bizzowl.com", "_self");
                 }}>contact@bizzowl.com</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         )}  
       
       {User && User?.uid?<a href="https://client.bizzowl.com/client/home">
      <img
        src="/Images/MyAccount.png" 
        alt="MyAccountIcon"
        // className="me-2"
        style={{ width: '75px', height: 'auto' }}
      />
      </a>:<a href="https://bizowl-auth.web.app/login">
          <img
        src="/Images/signInnew1.png" 
        alt="loginIcon"
        // className="me-2"
        style={{ width: '36px', height: 'auto' }}
      />
          </a>}
       </div>}
       
        
        {/* Desktop view  */}
        <div className="flex phonePositionRight webPaddingRight6p fontWeight500 hidePhoneView">
          <NavLink className="nav-link navLink" to="/services">
            Services
          </NavLink>

          <NavLink className="nav-link navLink" to='/ai-startup-tools'>
          Tools
          </NavLink>

          <NavLink className="nav-link navLink" to="/resources">
            Resources
          </NavLink>

          <NavLink className="nav-link navLink" to="/business-support" onClick={() => dispatch(setIsVisible(true))}>
            Guidance
          </NavLink>

          <NavLink className="nav-link navLink"     
          // onClick={() => setOpen((prev) => !prev)}  style={styles.navLink} 
          onClick={() => toggleDropdown("support")}
          style={styles.navLink}
          >

            Support
            <motion.span
          animate={{ rotate: openDropdown === "support" ? 180 : 0 }}
          transition={{ duration: 0.2 }}
          style={{ position:"relative",marginLeft: '8px' }}
        >
          <FiChevronDown />
         
        </motion.span>
        <hr 
    style={{
      position: 'absolute',
      width: '75px',
      height: '0px',
      left: '460px',
      top: '9px',
      border: '0.5px solid rgba(38, 50, 56, 0.35)',
      transform: 'rotate(90deg)',
      borderWidth: "0.5px"
    }} 
  />
         
          <AnimatePresence>          
            {
            openDropdown === "support" &&
            <motion.div
        initial="closed"
        animate="open"
          exit="closed"
        variants={dropdownVariants}
        style={styles.dropdownStyles }
      >
        <motion.div style={styles.item}>
          <p className="m-0 bg-[#D7E9FD] text-[#1C6ED0] font-medium text-sm leading-5 px-8 py-2 rounded-full">
            My Account
          </p>
        </motion.div>
        <motion.div style={styles.item} variants={itemVariants} onClick={(e) => e.stopPropagation()}>
          <img
            src="/Images/carbon_dashboard.png"
            alt="dashboardIcon"
            className="me-2"
          />
       
            <a href=" https://client.bizzowl.com/client/home" className="nav-link navLink">Dashboard</a>
            
         
        </motion.div>
        <motion.hr style={styles.hr} />
        <motion.div style={styles.item} variants={itemVariants}>
          <img
            src="/Images/getSupport.png"
            alt="chatIcon"
            className="me-2"
          />
          <NavLink className="nav-link navLink" to="/support">
            Get Support
          </NavLink>
        </motion.div>
        <motion.hr style={styles.hr} />
        <motion.div style={styles.item} >
          <p className="m-0 bg-[#D7E9FD] text-[#1C6ED0] font-medium text-sm leading-5 px-8 py-2 rounded-full">
            Contact Us
          </p>
        </motion.div>
        <div className="flex mt-2 "  >
          <a
            href="https://wa.me/message/MR4KKWOY4H62O1"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <motion.div style={styles.item} variants={itemVariants}>
            <div className="d-flex flex-column" style={{ alignItems: 'center', width: 'auto' }} >
              <img
                src="/Images/whatsappnew.png"
                alt="whatsappIcon"
                className=""
                style={{ width: '40px', height: '40px' }}
               
              />
              <p className="text-center text-black" style={{ fontSize: '12px' }}>
                Connect on <br /> WhatsApp
              </p>
            </div>
            </motion.div>
          </a>
          <motion.div style={styles.item} variants={itemVariants}>
          <NavLink className="navLinks textCapitalize" to="/contactUs">
            <div className="d-flex flex-column ml-5" style={{ alignItems: 'center', width: 'auto' }} variants={itemVariants}>
              <img
                src="/Images/callnew.png"
                alt="callIcon"
                className="ml-1"
                style={{ width: '40px', height: '40px' }}
              />
              <p className="text-center text-black" style={{ fontSize: '12px' }} >
                Request <br /> Callback
              </p>
            </div>
           
          </NavLink>
          
          </motion.div>
         
        </div>
    
      </motion.div>
          
 

       
          }
          </AnimatePresence>

          </NavLink>
          <img
        src="/Images/expertsnew1.png" 
        alt="talkToExpertsIcon"
        className="me-3 ml-3 cursor-pointer"
        // onClick={() => setShowExpertsDropdown((prev) => !prev)}
        onClick={() => toggleDropdown("experts")}
        style={{ width: '65px', height: 'auto' }}
        // style={{ width: '55px', height: 'auto' }}
        // onClick={toggleModal}
      />
      {/* Experts Dropdown */}
      <AnimatePresence>
        {openDropdown === "experts" && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={dropdownVariants}
            style={styles.expertsDropdownStyles}
           
          >
              {/* First Item */}
            <motion.div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
              variants={itemVariants}
            >
              <img
                src="/Images/agentIcon.png"
                alt="agent icon"
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "8px",
                }}
              />
              <div style={{ lineHeight: "1.2" }}>
                <p style={{ margin: "0", fontWeight: "500", fontSize: "13px", color: "#333" }}>
                  Help Regarding Service & Support
                </p>
                <p style={{ margin: "5px 0 0 0", fontSize: "14px", color: "#1C6ED0" }}>
                  +91 9354651433
                </p>
              </div>
            </motion.div>

            {/* Divider */}
            <motion.hr style={{ margin: "10px 0", borderColor: "#ddd",   borderWidth: "1.5px",width:"100%",  height: "3px" }} />

            {/* Second Item */}
            <motion.div
              style={{ display: "flex", alignItems: "center" }}
              variants={itemVariants}
            >
              <img
                src="/Images/carbon_email-new.png"
                alt="email icon"
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "14px",
                }}
              />
              <div style={{ lineHeight: "1.2" }}>
                <p style={{ margin: "0", fontWeight: "500", fontSize: "13px", color: "#333" }}>
                  Query Related to Startup?
                </p>
                <p
                  onClick={() => window.open("mailto:contact@bizzowl.com", "_self")}
                  style={{
                    margin: "5px 0 0 0",
                    fontSize: "14px",
                    color: "#1C6ED0",
                    cursor: "pointer",
                  }}
                >
                  contact@bizzowl.com
                </p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {User && User?.uid?<a href="https://client.bizzowl.com/client/home">
      <img
        src="/Images/MyAccount.png" 
        alt="MyAccountIcon"
        // className="me-2"
        style={{ width: '100px', height: 'auto' }}
      />
      </a>:<a href="https://bizowl-auth.web.app/login">
          <img
        src="/Images/signInnew1.png" 
        alt="loginIcon"
        // className="me-2"
        style={{ width: '58px', height: 'auto' }}
      />
          </a>}
   

          

          {/* <NavLink className="nav-link navLink" to="/company-name-generator">
            Business Name Generator
          </NavLink> */}
         
              {/*<div className="nav-link navLink cursor-pointer" onClick={()=>navigate("/ai-startup-tools")}><NavLink style={{color: "#000"}} to='/ai-startup-tools'>Tools</NavLink></div>*/}
              {/* <ul class="dropdown-menu">
                <li>
                  <NavLink
                    className="nav-link navLink"
                    to="/company-name-generator"
                  >
                    Business Name Generator
                  </NavLink>
                </li>
              </ul> */}
           

          {/* <NavLink className="nav-link navLink" to="/aboutUs">
            About us
          </NavLink> */}

          {/* <ul className="navbar-nav text-start">
            <li className="nav-item dropdown">
              <div
                className={
                  isActiveHoroscope
                    ? "active pointer nav-link dropdown-toggle navLink"
                    : "pointer nav-link dropdown-toggle navLink "
                }
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </div>
              <ul className="dropdown-menu hyperMenuSection dropdownItems">
                <div className="hyperMenuSectionFlex">
                  <div className="menuHeadSection headSectionStyle">
                    {hyperMainMenuHeadStack.map((val, index) => {
                      return (
                        <div
                          onMouseOver={() => {
                            setHyperMainMenuHeadStackValue(val.name);
                          }}
                          onClick={() => {
                            setHyperMainMenuHeadStackValue(val.name);
                          }}
                          key={index}
                          className={
                            hyperMainMenuHeadStackValue === val.name
                              ? "activeMenuSection menuHeadSection1"
                              : "menuHeadSection1"
                          }
                        >
                          <img
                            className="menuImages"
                            src={val.image}
                            alt={val.name}
                          />{" "}
                          {val.name}
                        </div>
                      );
                    })}
                  </div>
                  <div className="menuSubHeadSection headSectionStyle">
                    {hyperSubMenuStack.map((val, index) => {
                      if (
                        hyperMainMenuHeadStackValue.toLocaleLowerCase() ===
                        val.head.toLocaleLowerCase()
                      )
                        return (
                          <div
                            onMouseOver={() => {
                              setHyperSubMenuStackValue(val.name);
                            }}
                            onClick={() => {
                              setHyperSubMenuStackValue(val.name);
                            }}
                            key={index}
                            className={
                              hyperSubMenuStackValue === val.name
                                ? "activeMenuSection menuHeadSection1"
                                : "menuHeadSection1"
                            }
                          >
                            <img
                              className="menuImages"
                              src={val.image}
                              alt=""
                            />{" "}
                            {val.name}
                          </div>
                        );
                    })}
                  </div>
                  <div className="menuSubMenuSection headSectionStyle">
                    {hyperMenuStack.map((val, index) => {
                      if (
                        hyperMainMenuHeadStackValue.toLocaleLowerCase() ===
                          val.head.toLocaleLowerCase() &&
                        hyperSubMenuStackValue.toLocaleLowerCase() ===
                          val.mainMenu.toLocaleLowerCase()
                      )
                        return (
                          <div
                            onClick={() => {
                              hyperMenuStackValueFunction(val.name);
                            }}
                            key={index}
                            className={
                              hyperMenuStackValue === val.name
                                ? "activeMenuSection menuHeadSection1"
                                : "menuHeadSection1"
                            }
                          >
                            <img
                              className="menuImages"
                              src={val.image}
                              alt=""
                            />{" "}
                            {val.name}
                          </div>
                        );
                    })}
                  </div>
                </div>
              </ul>
            </li>
          </ul> */}

          {/* <NavLink className="nav-link navLink" to="/contactUs">
            Contact us
          </NavLink> */}
          
          
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-dark navBarBG  showPhoneView fullWidth " style={{ fontFamily: 'Cabin, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
   }}>
  {/**************************************************  Phone View **************************************************/}
  <div
    className="offcanvas offcanvas-start text-bg-dark fullWidth "
    tabIndex="-1"
    id="offcanvasDarkNavbar"
    aria-labelledby="offcanvasDarkNavbarLabel"
   
  >
    <div className="offcanvas-header">
    <button
        type="button"
        className="btn-close btn-close-black "
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        style={{ position: "absolute", left:"5px",right:"2px" }} ></button>
      <NavLink className="navbar-brand" to="/">
        <img
          alt=""
          src="/Images/logos/logo-of-BIZOWL--Business-Services.png"
          className="mobileSection headerImageStyle img-thumbnail border-0 bg-transparent ml-8"
        />
      </NavLink>
      <div className="top-right-icons d-flex align-items-center justify-content-end">
      <img
        src="/Images/expertsnew1.png" 
        alt="talkToExpertsIcon"
        className="me-3"
        style={{ width: '55px', height: 'auto' }}
        onClick={toggleModal}
      />
            {showModal && (
        <div className="modal  d-flex items-end justify-center " tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg  w-full " role="document">
          <div className="modal-content  rounded-t-[60px] overflow-hidden">
              <div className="modal-header bg-[#D7E9FD]  rounded-t-full ">
              
                <h5 className="modal-title text-black pl-3 mx-1">Talk to Expert</h5>
                <button type="button" className="btn btn-close mx-1"  onClick={toggleModal} aria-label="Close">
                  {/* <span aria-hidden="true">&times;</span> */}
                </button>
              </div>
              <div className="modal-body text-black ">
                <div className="d-flex align-items-center  mb-3 ">
                  <img
                    src="/Images/agentIcon.png"
                    alt="agent icon"
                    style={{ width: '30px', marginRight: '30px' }}
                  />
                  <div>
                    <p className="mb-0 text-gray-500">Help Regarding Service & Support</p>
                    <p className="mb-0">
                    <a href="tel:+919354651433" style={{ color: 'inherit', textDecoration: 'none' }}>
                      +91 9354651433 
                      </a>
                      </p>
                     
                  </div>
                </div>
                <hr className="w-full"/>
                <div className="flex items-center  mt-2">
                  <img
                    src="/Images/carbon_email-new.png"
                    alt="email icon"
                    style={{ width: '30px', marginRight: '30px' }}
                  />
                  <div>
                    <p className="mb-0 text-gray-500 ">Query Related to Startup?</p>
                    <p className="mb-0 " onClick={() => {
                WindowOpenLink("mailto:contact@bizzowl.com", "_self");
              }}>contact@bizzowl.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}  
    
    {User && User.uid?<a href="https://client.bizzowl.com/client/home">
      <img
        src="/Images/MyAccount.png" 
        alt="MyAccountIcon"
        // className="me-2"
        style={{ width: '75px', height: 'auto' }}
      />
      </a>:<a href="https://bizowl-auth.web.app">
      <img
        src="/Images/signInnew1.png" 
        alt="loginIcon"
        // className="me-2"
        style={{ width: '64px', height: 'auto' }}
      />
      </a>}
      
    </div>
    </div>

    <div className="offcanvas-body mobileViewHeader">
      {/* Wrapping each icon and link pair in a separate div */}
      <div className="d-flex flex-column gap-3 ml-3">
        {/* <div className=" w-[148px] h-[24px] left-7 top-20  border rounded-[35px]" > */}
        {/* <p className=" absolute  top-20 bg-[#D7E9FD] text-[#1C6ED0] font-medium text-base leading-5 px-8 py-2 rounded-full mt-3" >Explore Product</p> */}
        <div className="block text-left ">
        <p className="relative inline-block bg-[#D7E9FD] text-[#1C6ED0] font-medium text-base leading-5 px-8 py-2 rounded-full mt-1 mb-0"
  >
   Explore Product
</p>
</div>
        {/* </div> */}
         {/* Rectangle 4638 

position: absolute;
width: 148px;
height: 24px;
left: 28px;
top: 78px;

text-black  w-[109px] h-[19px font-cabin font-medium text-[16px] top-20
background: #D7E9FD;
border-radius: 35px; */}

 {/* Explore Product  position: absolute; width: 109px; height: 19px; left: 47px; top: 81px; font-family: 'Cabin'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; color: #1C6ED0; */}

        {/* Services */}
        <div className="d-flex align-items-center mt-2">
          <img src="/Images/serviceIcon.png" alt="serviceIcon" className="me-2" />
          <NavLink className="nav-link navLink" to="/services">Services</NavLink>
        </div>
        <hr style={{
          // position: "absolute",
          width: "320px",
          height: "0px",
          left: "27px",
          top: "200px",
          border: "0.5px solid rgba(0, 0, 0, 0.35)",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }} />
        {/* Tools */}
        <div className="d-flex align-items-center">
          <img src="/Images/AiToolsIcon.png" alt="toolIcon" className="me-2" />
          <div className="nav-link navLink" onClick={() => navigate("/ai-startup-tools")}>Tools</div>
        </div>
        <hr style={{
          // position: "absolute",
          width: "320px",
          height: "0px",
          left: "27px",
          top: "200px",
          border: "0.5px solid rgba(0, 0, 0, 0.35)",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }} />

        {/* Resources */}
        <div className="d-flex align-items-center">
          <img src="/Images/resourcesIcon.png" alt="resourceIcon" className="me-2" />
          <Link className="nav-link navLink" to="/resources">Resources</Link>
        </div>
        <hr style={{
          // position: "absolute",
          width: "320px",
          height: "0px",
          left: "27px",
          top: "200px",
          border: "0.5px solid rgba(0, 0, 0, 0.35)",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }} />
        {/* Guidance */}
        <div className="d-flex align-items-center">
          <img src="/Images/guidanceIcon.png" alt="guidanceIcon" className="me-2" />
          <NavLink
            to="/business-support"
            onClick={() => dispatch(setIsVisible(true))}
            className="nav-link navLink"
          >
            Guidance
          </NavLink>
        </div>
        <hr style={{
          // position: "absolute",
          width: "320px",
          height: "0px",
          left: "27px",
          top: "200px",
          border: "0.5px solid rgba(0, 0, 0, 0.35)",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }} />



<div className="d-flex align-items-center">
        <p className="relative  bg-[#D7E9FD] text-[#1C6ED0] font-medium text-base leading-5 px-8 py-2 rounded-full mt-3 ">
          My Account
        </p>
      </div>
      <div className="d-flex align-items-center">
        <img src="/Images/carbon_dashboard.png" alt="dashboardIcon" className="me-2" />
        <a href="https://client.bizzowl.com/client/home" className="nav-link navLink">Dashboard</a>
      </div>
      <hr style={{
        width: "320px",
        height: "0px",
        left: "27px",
        top: "200px",
        border: "0.5px solid rgba(0, 0, 0, 0.35)",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
      }} />
      <div className="d-flex align-items-center">
        <img src="/Images/getSupport.png" alt="chatIcon" className="me-2" />
        <NavLink className="nav-link navLink" to="/support">Get Support</NavLink>
      </div>
      <hr style={{
        width: "320px",
        height: "0px",
        left: "27px",
        top: "200px",
        border: "0.5px solid rgba(0, 0, 0, 0.35)",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
      }} />

      </div>

      {/*************************** for Login **************************************************/}
      {/* <hr id="login-hr" className="w-100 border-1 mt-auto " style={{ border: "1px solid rgba(0, 0, 0, 0.5)", height:"1px"}} /> */}
     {/* <div className="mt-auto p-3">
      <div className="d-flex align-items-center p-1 m-1">
        <p className="  bg-[#D7E9FD] text-[#1C6ED0] font-medium text-base leading-5 px-8 py-2 rounded-full  ">
          Contact us
        </p>
      </div>
      
      
      </div> */}
        {/* <hr className="my-3" />
        <hr style={{
          position: "absolute",
          width: "375px",
          // margin:"auto",
          height: "0px",
          left: "0px",
          top: "750px",
          border: "0.5px solid rgba(0, 0, 0, 0.5)",
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }} /> */}


          {/* <hr className="border-1" 
          style={{ 
            width: "100%", 
            height: "1px", 
            border: "1px solid rgba(0, 0, 0, 0.5)", 
            marginBottom: "16px" ,
             marginTop: "0"
            // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
          }} 
        />
  */}



  <div className="mt-auto p-3">
  <div className="d-flex align-items-center p-1 m-1 ">
  <p className="  bg-[#D7E9FD] text-[#1C6ED0] font-medium text-base leading-5 px-8 py-2 rounded-full  ">
          Contact us
        </p>
        </div>
        <div className="d-flex align-items-center p-1 justify-between " >
        <a href="https://wa.me/message/MR4KKWOY4H62O1" target="_blank" rel="noopener noreferrer">
          <div className="d-flex flex-column " style={{ alignItems: 'center', width: 'auto' }}>
            <img src="/Images/whatsappnew.png" alt="whastappIcon" className="" style={{ width: '40px', height: '40px' }}/>
            {/* <p className="text-black m-0">Connect on</p>
            <p className="text-black">WhatsApp</p> */}
            <p className="text-center text-black" style={{ fontSize: '12px' }}>Connect on <br /> WhatsApp</p>
        </div>
        </a>
        <NavLink className="navLinks textCapitalize" to="/contactUs">
        <div className="d-flex flex-column ml-4" style={{ alignItems: 'center', width: 'auto' }}>
              <img src="/Images/callnew.png" alt="callIcon" className="ml-1 "  style={{ width: '40px', height: '40px' }} />
              {/* <p className="text-black m-0">Request</p>
              <p className="text-black">Callback</p> */}
               <p className="text-center text-black" style={{ fontSize: '12px' }}>Request <br /> Callback</p>
        </div>
        </NavLink>
          {/* Login Icon and Link */}
          {/* <div className="d-flex align-items-center">
            <img
              src="/Images/stash_signin.png"
              alt="loginIcon"
              className="me-2"
          
            />
            <a href="https://bizowl-auth.web.app" className="nav-link navLink">
              Login
            </a>
          </div> */}

          {/* 24/7 Icon */}
          {/* <div >
          <img
            src="/Images/24-7 1.png"
            alt="24-7 icon"
            style={{marginLeft: "200px" }} 
          // marginleft 200px
          // marginLeft: "auto", marginRight: "20px",
          />
          </div> */}
         
        </div>
    
      
      </div>
      {/* Responsive styles */}
      <style jsx>{`
  /* Target common mobile screen sizes */
  @media (max-width: 430px) { /* iPhone 14 Pro Max, large Android devices */
    #login-hr {
      margin-bottom: -40vh;
      margin-top:auto;  /* Reduced margin for large phones */
    }

    
  }

  @media (max-width: 390px) { /* iPhone 12 Pro */
    #login-hr {
      margin-bottom: -40vh;
      margin-top:auto;  /* Adjust for iPhone 12 Pro */
    }

    
  }

  @media (max-width: 375px) { /* Smaller Android devices */
    #login-hr {
      margin-bottom: -30vh;
      margin-top: auto;  /* Adjust for smaller Android devices */
    }

   
  }
`}</style>

    </div>
  </div>
</nav>


    </>
  );
};

export default NavBar;
