import {
  useMediaQuery,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import BizowalLogo from "./assets/logo-of-BIZOWL--Business-Services.png";
import back from "./assets/Back-to-Quotes.png"
import PhonePic from "./assets/Vector (1).png";
import svg from "./../Services/assets/compareSVG.svg";

import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { partnerDB } from "../../config/firebase";
const CompareWebServices = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  const navigate = useNavigate();
  const [serviceIds, setServiceIds] = useState([]);
  const [indices, setIndices] = useState([]);
  const [BiddingData, setBiddingData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Optional: to handle errors
  useEffect(() => {
    const services = searchParams.get("services");
    if (services) {
      const serviceEntries = services.split(",");
      const ids = [];
      const idxs = [];

      serviceEntries.forEach(entry => {
        const [id, index] = entry.split("-");
        ids.push(id);
        idxs.push(index);
      });

      setServiceIds(ids);
      setIndices(idxs);
    }
  }, [searchParams]);

  const { bidID, serviceName, enquiryID } = useParams();

  const [features, setFeatures] = useState({});
  const [servicesData, setServicesData] = useState([]);
  const isMobile = useMediaQuery("(max-width:768px)");
  const isSmallMobile = useMediaQuery("(max-width:400px)");

  const getData = async () => {
    try {
      const collectionRef = collection(partnerDB, "biddingData");
      const enquiryQuery = query(collectionRef, where("EnquiryDetails.enquiryID", "==", enquiryID));
      const querySnapshot = await getDocs(enquiryQuery);
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const partnerCollectionRef = collection(partnerDB, `biddingData/${docId}/partners`);
        const bidIDs = bidID.split(",");
        console.log("bidIds:", bidIDs);
        const fetchBiddingData = async () => {
          const newBiddingArray = [];
          for (const bidId of bidIDs) {
            const partnerQuery = query(partnerCollectionRef, where("biddingDetails.bidID", "==", bidId));
            const partnerDoc = await getDocs(partnerQuery);
            const partnerData = partnerDoc.docs[0].data();
            newBiddingArray.push(partnerData);
          }
          setBiddingData([...newBiddingArray]);
        }
        await fetchBiddingData();
        setLoading(false);

      } else {
        console.log("data not found")
      }
    } catch (error) {
      console.log(error.message);
    }
  }



  useEffect(() => {
    getData();
  }, [serviceIds]);


  if (loading) {
    return <p>Loading...</p>;
  }


  const rows = ["Revisions", "Start Date", "Post Service Support", "Payment Structure", "Payment Ratio", "Technology Used", "Hosting Included"];
  const fieldData = ["revisions", "startDate", "postServiceSupport", "paymentStructure", "paymentRatio", "usedTech", "hostingIncluded"];

  const handleClick = (isMobile) => {
    if (isMobile) {
      // Open dialer with the phone number
      window.location.href = "tel:+919354651433";
    } else {
      // Open WhatsApp with a predefined message
      window.location.href = "https://wa.me/919354651433?text=Hello%20I%20need%20help";
    }
  };

  if (isMobile) {
    return (
      <div className="card" style={{
        backgroundColor: "#f7fcfb",
        position: "relative",
      }}>
        <div className="card-body" style={{
          backgroundColor: "#f7fcfb",
          position: "fixed",
          top: "0px",
          right: "0px",
          left: "0px",
          zIndex: "9999",

          boxShadow: "0 4px 6.4px 0 #c6cac9",
        }}>
          <div className="d-flex justify-content-between w-[90%] mx-auto">
            <div className="flex cursor-pointer" onClick={() => navigate(-1)}>
              <img src={back} width="120px" />
            </div>

            <div className="d-flex align-items-center">
              <a href="/">
                <img
                  src={BizowalLogo}
                  alt="BizowlLogo"
                  width={isMobile ? "120px" : "200px"}
                  onClick={() => navigate("/")}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center h-screen w-full">
          <div className="flex flex-col h-[90%] w-[95%] mt-[70px]">
            <div className="flex justify-between w-full h-[200px]">
              {BiddingData.map((bidOffer, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: isMobile ? "" : "space-around",
                      height: isSmallMobile ? "max-content" : "160px",
                      width: isSmallMobile ? "160px" : "180px",
                      marginLeft: isMobile ? "" : "20px",
                      borderRadius: "25px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      padding: isSmallMobile ? "10px" : "15px",
                    }}
                  >
                    <div
                      style={{
                        border: "2px solid #70a6e6",
                        borderRadius: "10px",
                        width: "60px",
                        height: "40px",
                        padding: "7px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3 style={{
                        fontSize: "1rem",
                        fontWeight: "520",
                        color: "#1c6ed0"
                      }} >
                        {bidOffer?.partnerDetails?.partnerName.substring(0, 3).toUpperCase()
                        }
                      </h3>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "20px"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            width: "80px",
                            textAlign: "left"
                          }}
                        >
                          <p className="text-[8px]">Total Price Included GST</p>
                          <p className="text-[10px] font-semibold">₹{bidOffer?.biddingDetails?.price}</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                            textAlign: "left"
                          }}
                        >
                          <p className="text-[8px]" style={{ width: isSmallMobile ? "40px" : "" }}>Delivery Time</p>
                          <p className={`text-[10px] font-semibold ${isSmallMobile ? "mt-0" : "mt-[11px]"}`}>{bidOffer?.biddingDetails?.timeline}</p>
                        </div>
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        <Button fullWidth variant="contained" style={{ width: "90px", borderRadius: "10px", fontSize: "8px" }}>
                          Book Now
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>
            <div className="flex w-full h-max bg-white rounded-sm">
              <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                <Table style={{ boxShadow: "none" }}>
                  <TableBody style={{ height: "max-content" }}>
                    {rows.map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell style={{ border: "none", fontWeight: "600", fontSize: "10px" }}>{row}</TableCell>
                          {BiddingData[0] ? <TableCell className="text-center" style={{ border: "none", fontSize: "10px" }}>{BiddingData[0].biddingDetails?.[fieldData[index]]}</TableCell> : ""}
                          {BiddingData[1] ? <TableCell className="text-center" style={{ border: "none", fontSize: "10px" }}>{BiddingData[1].biddingDetails?.[fieldData[index]]}</TableCell> : ""}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="card" style={{
      backgroundColor: "#f7fcfb",
      position: "relative",
    }}>
      <div className="card-body" style={{
        backgroundColor: "#f7fcfb",
        position: "fixed",
        top: "0px",
        right: "0px",
        left: "0px",
        zIndex: "9999",

        boxShadow: "0 4px 6.4px 0 #c6cac9",
      }}>
        <div className="d-flex justify-content-between w-[90%] mx-auto">


          <div className="d-flex align-items-center">
            <a href="/">
              <img
                src={BizowalLogo}
                alt="BizowlLogo"
                width={isMobile ? "120px" : "200px"}
                onClick={() => navigate("/")}
              />
            </a>
          </div>
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => handleClick(isMobile)}
          >
            <img
              src={PhonePic}
              alt="PhonePic"
              width={isMobile ? "30px" : "40px"}
            />
            <p style={{
              color: "#407bff"
            }}>Need help?</p>
          </div>

        </div>
      </div>

      <div className=" absolute cursor-pointer" style={{
        margin: isMobile ? "6rem" : "6rem",
        left: isMobile ? "-4rem" : "-2rem"
      }} onClick={() => navigate(-1)}>
        <img src={back} width="120px" />

      </div>

      {/*  */}

      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "max-content", width: "100%" }}>
        <div style={{ display: "flex", backgroundColor: "white", height: "90%", width: "95%", marginTop: "150px", borderRadius: "30px" }}>
          <TableContainer component={Paper} style={{ boxShadow: "none", width: "max-content" }}>
            <Table style={{ boxShadow: "none" }}>
              <TableBody style={{ height: "max-content" }}>
                {/* Image and Cards row */}
                <TableRow>
                  <TableCell style={{ border: "none", fontWeight: "600", fontSize: "10px", width: "max-content" }}>{<div style={{ display: "flex", height: "220px", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {!isMobile && (<img alt="compare" src={svg} style={{ height: "200px", width: "250px" }} />)}
                    </div>
                  </div>}</TableCell>
                  {BiddingData.map((bidOffer, index) => {
                    return (
                      <TableCell style={{ border: "none" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: isMobile ? "" : "space-around",
                            height: "200px",
                            width: "280px",
                            borderRadius: "25px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            padding: "15px",
                          }}
                        >
                          <div
                            style={{
                              border: "2px solid #70a6e6",
                              borderRadius: "10px",
                              width: "80px",
                              padding: "7px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h3 style={{
                              fontSize: "1.5rem",
                              fontWeight: "520",
                              color: "#1c6ed0"
                            }} >
                              {bidOffer?.partnerDetails?.partnerName.substring(0, 3).toUpperCase()
                              }
                            </h3>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                  textAlign: "left"
                                }}
                              >
                                <p className="text-[12px] font-bold">Total Price Included GST</p>
                                <p className="text-[14px] font-bold">₹{bidOffer?.biddingDetails?.price}</p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                  textAlign: "left"
                                }}
                              >
                                <p className="text-[12px] font-bold">Delivery Time</p>
                                <p className="text-[14px] font-bold">{bidOffer?.biddingDetails?.timeline}</p>
                              </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                              <Button fullWidth variant="contained" style={{ borderRadius: "10px", width: "150px", alignSelf: "center" }}>
                                Book Now
                              </Button>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
                {rows.map((row, index) => {
                  return (
                    <TableRow>
                      <TableCell style={{ border: "none", fontWeight: "600",backgroundColor:(index%2===0)?"white":"#f7fcfb" }}>{row}</TableCell>
                      {BiddingData[0] ? <TableCell className="text-center" style={{ border: "none", width: "300px",backgroundColor:(index%2===0)?"white":"#f7fcfb" }}><p>{BiddingData[0].biddingDetails?.[fieldData[index]]}</p></TableCell> : ""}
                      {BiddingData[1] ? <TableCell className="text-center" style={{ border: "none", width: "300px",backgroundColor:(index%2===0)?"white":"#f7fcfb" }}><p>{BiddingData[1].biddingDetails?.[fieldData[index]]}</p></TableCell> : ""}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>


      </div>
    </div>
  );
};

export default CompareWebServices;
