import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Img,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";

import FileImage from "../../assets/File-Preview 1.png";
import { IoEyeOutline } from "react-icons/io5";
import { MdCalendarViewMonth  } from "react-icons/md";

const PortfolioCard = () => {
  return (
    <Box
      objectFit="cover"
      overflow="hidden"
      boxShadow={"0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)"}
      backgroundColor="#FFFFFF"
      padding={".8rem"}
      className="text-xs md:text-base my-4 rounded-[1rem] "
    >
      <Text fontWeight="bold">{"Project Name"}</Text>
      <Flex
        my={".8rem"}
        alignItems={"center"}
        backgroundColor="#D7E9FD"
        justifyContent={"center"}
        className=" rounded-[1rem]  w-[9.5rem] md:w-[18rem] h-[5rem] md:h-[9rem] "
      >
        <Img src={FileImage} className={"h-16 md:h-32"} />
      </Flex>
      <Flex flexDirection="column" m={{ sm: ".5rem", md: "1rem" }}>
        <Flex justifyContent="space-between">
          <Text>{"Client Name"}</Text>
          <Flex alignItems="center" cursor={"pointer"}>
            <Icon as={IoEyeOutline} />
            <Text>View Details</Text>
          </Flex>
        </Flex>
        <Text color="#007CFF">{"Web Development"}</Text>
        <Flex alignItems="center" gap={{ sm: ".1rem", md: ".3rem" }}>
          <Icon as={MdCalendarViewMonth} />
          <Text>{"07-07-2025"}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};
export default PortfolioCard;