import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import logo_new from "./assets/logo_new.png";
import { Flex, Image } from "@chakra-ui/react";
import "./prGenerator.css";
import prTools from "./assets/prTools.png";
import fill from "./assets/fill.png";
import generate from "./assets/Generate.png";
import vector from "./assets/Vector.png";
import { Try } from "@mui/icons-material";
import axios from "axios";
import { BiDislike, BiLike } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBar from "../../Components/Header/NavBar";
import Footer from "../../Components/Footer/Footer";
import ApplyBodyStylesOnLoad from "../../Components/ApplyBodyStylesOnLoad ";
const PrGenerator = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isBig = useMediaQuery("(min-width: 769px) and (max-width: 1110px)");

  const labelStyle = {
    fontSize: isMobile ? "" : "large",
    fontWeight: "600",
    minWidth: "100%",
    lineHeight: "3rem",
  };

  const whatPrIs = [
    {
      title: "Easy to Use",
      desc: "Simply input your key information, and the tool will craft a compelling press release in seconds, eliminating the need for advanced writing skills.",
    },
    {
      title: "Time-Saving",
      desc: " It generates content instantly, allowing you to focus on other crucial tasks like media outreach or marketing.",
    },

    {
      title: "Cost-Effective",
      desc: "Compared to hiring professional writers or PR agencies, an AI press release generator is a budget-friendly solution.",
    },

    {
      title: "Consistency and Quality",
      desc: "AI tools ensure your press release is well-structured, follows standard press release formats, and maintains a professional tone.",
    },

    {
      title: "Customizable Output ",
      desc: " You can easily edit and tailor the generated content to better fit your brand voice or specific needs, ensuring a perfect match every time.",
    },
  ];

  const prType = [
    "Event",
    "Company News",
    "Product Launch",
    "Partnership",
    "New hire",
    "Award",
    "Achievent",
    "other",
  ];
  const tone = ["Professional", "Casual", "Dry", "Edgy"];

  const [formData, setFormData] = useState({
    prType: "",
    details: "",
    tone: "",
    companyName: "",
    companyDesc: "",
    adQuotes: "",
  });
  const [loading, setLoading] = useState(false);
  const [prData, setPrData] = useState();
  const [showCopied, setShowCopied] = useState(false);

  const [displaying, setDisplaying] = useState(true);
  console.log(formData);

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const cleanResponseData = (data) => {
    // Replace asterisks with an empty string
    return data
                .pressRelease
                .replace(/\*/g, "")
                .replace(/#/g, "\n");
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(prData)
      .then(() => {
        setShowCopied(true);
        setTimeout(() => {
          setShowCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard", error);
      });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setDisplaying(false);
    setLoading(true);
    try {
      const response = await axios.post(
        "https://backend.bizzowl.com/prGenerator",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);

      // Clean the response data
      const cleanedData = cleanResponseData(response.data);

      // Set the cleaned data
      setPrData(cleanedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="flex flex-col h-screen overflow-auto font-cabin w-full relative"
      style={{
        minHeight: "100vh"
      }}
    >
     <ApplyBodyStylesOnLoad />
      <Helmet>
        <title>
          Press Release Generator Free AI Tool (2024) | Create Press Releases
          Instantly
        </title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Generate professional press releases for free with our AI-powered tool. Create and customize your press releases instantly with our easy-to-use generator."
        />
        <meta
          name="keywords"
          content="Press Release Generator Free, AI Press Release Tool, Free Press Release Generator, Create Press Release, Online Press Release Tool, AI Tool for Press Releases, Press Release Writing, Press Release Maker, Free Press Release Writing Tool"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizzowl" />
        <meta http-equiv="Content-Language" content="en" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="canonical"
          href="https://bizzowl.com/ai-startup-tools/ai-press-release-generator-free"
        />
        <meta
          property="og:title"
          content="Press Release Generator Free AI Tool (2024) | Create Press Releases Instantly"
        />
        <meta
          property="og:description"
          content="Use our free AI tool to create press releases quickly and easily. Perfect for startups and businesses looking to announce their news professionally."
        />
        <meta
          property="og:image"
          content="https://bizzowl.com/path-to-image.jpg"
        />
        <meta
          property="og:url"
          content="https://bizzowl.com/ai-press-release-generator-free"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Press Release Generator Free AI Tool (2024) | Create Press Releases Instantly"
        />
        <meta
          name="twitter:description"
          content="Generate press releases for free with our AI-powered tool. Start creating your professional press release today!"
        />
        <meta
          name="twitter:image"
          content="https://bizzowl.com/path-to-image.jpg"
        />

        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "SoftwareApplication",
  "name": "Press Release Generator AI Tool",
  "url": "https://bizzowl.com/ai-press-release-generator-free",
  "description": "Generate professional press releases for free with our AI-powered tool. Ideal for startups and businesses looking to create press releases quickly and efficiently.",
  "applicationCategory": "BusinessApplication",
  "operatingSystem": "All",
  "offers": {
    "@type": "Offer",
    "price": "0.00",
    "priceCurrency": "INR",
    "itemCondition": "https://schema.org/NewCondition",
    "url": "https://bizzowl.com/ai-press-release-generator-free"
  },
  "softwareVersion": "1.0",
  "provider": {
    "@type": "Organization",
    "name": "Bizzowl",
    "url": "https://bizzowl.com"
  },
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://bizzowl.com/ai-startup-tools"
  },
  "about": {
    "@type": "Thing",
    "name": "Press Release Generator AI Tool",
    "description": "AI-powered tool to help startups and businesses write press releases quickly and for free."
  },
  "additionalType": "https://schema.org/FreeSoftware"
  }`}
        </script>
      </Helmet>
      {/* Navbar */}
      {/* <nav className={`sticky top-0  shadow-none  bg-[#EFF2F9] ${isMobile?"h-[3rem] z-200 pl-5 pb-[2rem] pt-5":"h-[5rem] z-[2] pl-5 pb-[3rem] pt-5"}`}>
      <img src={logo_new} alt="Logo" className={`cursor-pointer ${isMobile?"h-[30px] ":""}`} onClick={()=>navigate("/")}/>
    </nav> */}
    {!isMobile && <NavBar />}

      <div
        className={`${
          isMobile ? "flex flex-col overflow-y-auto" : "flex flex-row "
        } w-[100%] gap-8 bg-[#f7fcfb]`}
        style={{
          background: `linear-gradient(360deg, #f9fdfc 30%, #E4F0F7 90%)`,
        }}
      >
        {isMobile && <NavBar />}
        {/* left side scrollable */}
        <div
          className={`flex flex-col ${
            isMobile ? "w-[100%] " : " w-[50%]"
          } overscroll-y-auto mt-12 mb-12 `}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: isMobile ? (displaying ? "flex" : "none") : "",
          }}
        >
          <div className="w-[90%]">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: isMobile ? "" : "100%",
              }}
            >
              <p className={`${isMobile ? "" : ""} text-[gray]`}>
                Press Release Generator{" "}
              </p>
              <div
                className={`w-[80%] mt-2  ${
                  isMobile ? "text-2xl" : isBig ? "text-3xl" : "text-4xl  "
                }`}
              >
                <p>Press Release Generator</p>
                <span className={`text-[#407BFF]`}>Free Ai Tool ( 2024 )</span>
              </div>
              <div className="h-[1px] w-[80%] mx-auto bg-[#d7ddde] mt-7"></div>
              <div
                className={`flex justify-center items-center bg-[#D7E9FD] rounded-[2rem] mt-7 ${
                  isMobile ? "w-[80%]" : ""
                } min-w-[80%]`}
              >
                <img
                  src={prTools}
                  className={`${isMobile ? "w-[150px]" : ""}`}
                />
              </div>

              <p
                className={` ${
                  isMobile
                    ? "mt-8 text-xl font-[200]"
                    : isBig
                    ? "text-2xl mt-8"
                    : "mt-16 text-3xl "
                }`}
              >
                Ai Press Release Writer{" "}
              </p>
              <p
                className={`${
                  isMobile
                    ? "mt-3 text-md text-justify opacity-90 font-[100]"
                    : isBig
                    ? "mt-3 opacity-90 font-[100]"
                    : "mt-3 font-[100]"
                }`}
              >
                Bizowl's AI Press Release Writer is a cutting-edge tool designed
                to transform your ideas into polished press releases with ease.
                Powered by advanced AI models, this tool takes your provided
                summary and generates professional, compelling press releases in
                minutes.
              </p>
            </div>

            <form
              onSubmit={submitHandler}
              className="flex flex-col mt-4 min-w-[100%]"
              style={{
                alignItems: "start",
              }}
            >
              <label htmlFor="prType" style={labelStyle}>
                Type of Press Release
                <br />
                <select
                  className="bg-white rounded-md w-full p-3 h-14 font-[100] text-[grey]"
                  name="prType"
                  id="prType"
                  defaultValue=""
                  onChange={handleFormData}
                >
                  <option disabled value="">
                    Select
                  </option>
                  {prType.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="details" style={labelStyle}>
                Details
                <br />
                <textarea
                  name="details"
                  id="details"
                  onChange={handleFormData}
                  className={`w-full text-[grey] rounded-md h-[200px] p-3 leading-6 ${
                    isMobile ? "" : "font-[100]"
                  }`}
                  placeholder="Draft some details about your press release"
                ></textarea>
              </label>

              <label htmlFor="tone" style={labelStyle}>
                Tone
                <br />
                <select
                  className="bg-white rounded-md w-full p-3 h-14 font-[100] text-[gray]"
                  name="tone"
                  id="tone"
                  defaultValue=""
                  onChange={handleFormData}
                >
                  <option disabled value="">
                    Select
                  </option>
                  {tone.map((tone, index) => (
                    <option key={index} value={tone}>
                      {tone}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="companyName" style={labelStyle}>
                Company Name
                <br />
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  onChange={handleFormData}
                  className="w-full rounded-md p-3 h-14 font-[100] text-[gray] "
                  placeholder="Your Brand Name"
                />
              </label>

              <label htmlFor="companyDesc" style={labelStyle}>
                Company Description
                <br />
                <textarea
                  type="text"
                  name="companyDesc"
                  id="companyDesc"
                  onChange={handleFormData}
                  className="w-full rounded-md h-28 p-3 leading-6 font-[100]  text-[gray]"
                  placeholder="Your Value Proposition? "
                ></textarea>
              </label>

              <label htmlFor="adQuotes" style={labelStyle}>
                Ad Quotes of
                <br />
                <input
                  type="text"
                  name="adQuotes"
                  id="adQuotes"
                  onChange={handleFormData}
                  className="w-full rounded-md h-14 p-3 text-[gray] font-[100]  "
                  placeholder="What does your CEO, CFO, CMO, CTO have to say or add?"
                ></input>
              </label>
              <br />
              <button className="bg-[#407BFF] text-white font-light rounded-lg mt-12">
                Generate Press Release
              </button>
            </form>

            <div
              className="flex flex-col mt-20"
              style={{
                alignItems: "start",
                textAlign: "left",
                gap: "1rem",
              }}
            >
              <p
                className={`${
                  isMobile ? "text-2xl" : isBig ? "text-2xl" : " text-3xl"
                }`}
              >
                What is AI Press release writer?{" "}
              </p>
              <div className="h-[1px] w-[80%]  bg-[#d7ddde] "></div>
              <p className={`text-[#5a6569] ${isMobile ? "" : "text-lg"}`}>
                An AI Press Release Writer is a tool that helps you create
                professional press releases quickly and efficiently using
                artificial intelligence. Designed to streamline the writing
                process, this tool can save time, reduce errors, and help
                businesses, startups, and individuals generate newsworthy
                content that grabs attention. Here's why an AI Press Release
                Generator is a game-changer:
              </p>

              {whatPrIs.map((item, index) => (
                <p className={`text-[#5a6569] ${isMobile ? "" : "text-lg"}`}>
                  <span className="font-bold">{item.title}</span>- {item.desc}
                </p>
              ))}

              <div className="flex-col mt-12">
                <p
                  className={`${
                    isMobile ? "text-2xl" : isBig ? "text-2xl" : " text-3xl"
                  }`}
                >
                  Who can benefit from using an AI Press Release Generator?
                </p>
                <div
                  className="flex flex-col justify-start items-start gap-4 mt-4"
                  style={{
                    alignItems: "start",
                  }}
                >
                  <div className="flex gap-4">
                    <div
                      className={`flex  justify-center items-center rounded-full ${
                        isMobile
                          ? "w-[25px] h-[25px]"
                          : "w-[33px] h-[33px] rounded-full "
                      } bg-[#D7E9FD]`}
                    >
                      <img
                        src={vector}
                        className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                      />
                    </div>
                    <p>Startup Founders</p>
                  </div>

                  <div className="flex gap-4">
                    <div
                      className={`flex  justify-center items-center rounded-full ${
                        isMobile
                          ? "w-[25px] h-[25px]"
                          : "w-[33px] h-[33px] rounded-full "
                      } bg-[#D7E9FD]`}
                    >
                      <img
                        src={vector}
                        className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                      />
                    </div>
                    <p>Marketing Team</p>
                  </div>

                  <div className="flex gap-4">
                    <div
                      className={`flex  justify-center items-center rounded-full ${
                        isMobile
                          ? "w-[25px] h-[25px]"
                          : "w-[33px] h-[33px] rounded-full "
                      } bg-[#D7E9FD]`}
                    >
                      <img
                        src={vector}
                        className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                      />
                    </div>
                    <p>PR Professionals</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 text-left">
              <p
                className={`${
                  isMobile ? "text-2xl" : isBig ? "text-2xl" : " text-3xl"
                }`}
              >
                How to use this AI Press release Writer?{" "}
              </p>
              <div className={`flex mt-4 gap-10 flex-wrap justify-center`}>
                <div
                  className={`bg-white transform transition-transform hover:scale-110 flex flex-col rounded-md justify-start items-center text-center ${
                    isMobile
                      ? "min-w-[300px] min-h-[280px] max-w-[300px] max-h-[280px]"
                      : "min-w-[317px] min-h-[297px] max-w-[317px] max-h-[297px]  p-7"
                  } gap-3 shadow-md`}
                >
                  <div
                    className={`flex flex-col ${
                      isMobile ? "w-[95%] gap-2 p-3" : "gap-3"
                    }`}
                  >
                    <p className={`${isMobile ? "" : "text-[20px]"}`}>
                      Fill Details
                    </p>
                    <Box>
                      <img src={fill} />
                    </Box>
                    <p className={`${isMobile ? "" : "text-[16px]"}`}>
                      Take your time to provide precise information to ensure
                      the best results. The more detailed your input, the better
                      your press release will be.
                    </p>
                  </div>
                </div>

                <div
                  className={`bg-white transform transition-transform hover:scale-110 flex flex-col rounded-md justify-start items-center text-center ${
                    isMobile
                      ? "min-w-[300px] min-h-[280px] max-w-[300px] max-h-[280px]"
                      : "min-w-[317px] min-h-[297px] max-w-[317px] max-h-[297px] p-7 gap-3"
                  } shadow-md`}
                >
                  <div
                    className={` flex flex-col ${
                      isMobile ? "w-[95%] gap-2 p-3" : "gap-3"
                    }`}
                  >
                    <p className={`${isMobile ? "" : "text-[20px]"}`}>
                      Generate
                    </p>
                    <Box>
                      <img src={generate} />
                    </Box>
                    <p className={`${isMobile ? "" : "text-[16px]"}`}>
                      Click the "Generate" button to create your press release.
                      Review the generated text, make any necessary adjustments
                      to suit your needs, and if you’re not satisfied, you can
                      rewrite
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* right side  */}

        <div
          className={`   ${
            isMobile
              ? ` mt-8 mb-16 mx-auto items-start hidden justify-center ${
                  !displaying && "flex min-w-[300px]"
                }`
              : "top-[7rem]  h-[100vh] w-full max-w-[547px]  left-[50%] fixed"
          }`}
          style={{
            position: !isMobile && "fixed", // Use relative position to ensure container appears above footer
            zIndex: 0, // Add a z-index to ensure container appears below footer
          }}
        >
          <div
            className={`bg-white rounded-lg flex flex-col  ${
              isMobile
                ? "w-[100%] min-w-[90%] min-h-[50%]"
                : "max-w-[547px] max-h-[597px]  min-h-[597px] items-center   "
            } shadow-md`}
            style={{
              width: isBig && "70%",
              width: isMobile && "350px",
              maxWidth: isMobile ? "100%" : "547px", //
            }}
          >
            <div className="w-[90%] mt-8" style={{}}>
              <div
                className="flex flex-col gap-3 "
                style={{
                  alignItems: "start",

                  justifyContent: "space-between",
                  paddingBottom: isMobile ? "1rem" : "",
                }}
              >
                <p className="text-xl font-semibold">Press Release</p>
                <div className="h-[1px] w-[100%] mx-auto bg-[#d7ddde] "></div>
                <div
                  className=" max-h-[400px] min-h-[400px] w-full hide-scrollbar"
                  style={{ overflowY: "auto", overflowX: "hidden" }}
                >
                  <p
                    className={`text-left ${
                      isMobile ? "text-md font-[200]" : "text-lg font-light"
                    } `}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",

                          position: "relative",
                          paddingTop: isMobile ? "8rem" : "8rem",
                        }}
                      >
                        <iframe
                          src="https://giphy.com/embed/06aA0f1QpAshEfFbFH"
                          width="100%"
                          height="100%"
                          // style={{ position: 'relative' }}
                          className="giphy-embed mx-auto"
                          allowFullScreen
                          title="Giphy"
                        ></iframe>
                      </div>
                    ) : (
                      prData
                    )}
                  </p>
                </div>

                <div
                  className={`flex justify-between w-[100%] ${
                    isMobile ? "" : ""
                  }`}
                >
                  <button
                    disabled={prData === undefined}
                    className="bg-[#407BFF] text-white transform transition-transform hover:scale-110"
                    onClick={submitHandler}
                  >
                    Another Variant
                  </button>
                  <Flex gap="1rem">
                    <div className="relative">
                      <MdContentCopy
                        size="2rem"
                        cursor="pointer"
                        className=" transform transition-transform hover:scale-110"
                        onClick={handleCopy}
                      />
                      {showCopied && (
                        <div
                          className={`${
                            isMobile
                              ? "absolute -top-10 left-7 "
                              : "transform  -top-10 left-7  absolute"
                          } text-white text-sm px-4 py-2 rounded-tr-md rounded-br-md rounded-t-md bg-[#757880] `}
                        >
                          Copied!
                        </div>
                      )}
                    </div>
                    <BiLike
                      size="2rem"
                      cursor="pointer"
                      className="transform transition-transform hover:scale-110"
                    />
                    <BiDislike
                      size="2rem"
                      cursor="pointer"
                      className="transform transition-transform hover:scale-110"
                    />
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div
          className="absolute bottom-0 w-full"
          style={{
            position: "relative",
            zIndex: 1,
            padding: "20px",
          }}
        >
         
        </div>
      )}

   
    </div>
  );
};

export default PrGenerator;
