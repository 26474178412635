import { Link } from "react-router-dom";
import MessageImg from "./assets/Vector (2).png";
import {useState,useContext} from "react";
import { collection,addDoc,where,query,getDocs } from "firebase/firestore";
import { primaryDB } from "../../config/firebase";
import { userContext } from "../../App";
import { nanoid } from "nanoid";
import {useToast} from "@chakra-ui/react";
import SucessMessage from "../../Components/SucessMessage/SucessMessage"


const ChatPopup = ({setIsVisible,row,selectedDoc}) => {
  const [messageText,setMessageText]=useState();
  const User=useContext(userContext);
  const handleTextChange=(e)=>{
      console.log(e.target.value);
      setMessageText(e.target.value);
  }

  const toast = useToast();

  const generateId=()=>{
    return nanoid(16);
  }

  // const handlemessagesend=()=>{
  //   alert("Message Send Sucessfully");  
  // }

  const sendMessage=async()=>{
    try{
      const chatCollectionRef=collection(primaryDB,"Chats");
      const userData={chatId:generateId(),clientId:User && User?.uid,partnerId:selectedDoc?.partnerDetails?.partnerId,clientName:User?.personal?.fullName,partnerName:selectedDoc?.partnerDetails?.partnerName,createdAt:new Date()};
      const chatQuery=query(chatCollectionRef,where("clientId","==",User?.uid),where("partnerId","==",selectedDoc?.partnerDetails?.partnerId));
      const querySnapshot=await getDocs(chatQuery);
      if(querySnapshot.empty){
        const addedDoc=await addDoc(chatCollectionRef,userData);
        const docId=addedDoc.id;
        console.log("docId:",docId);
        if(docId){
          const subCollectionRef=collection(primaryDB,`Chats/${docId}/Messages`);
          const messageData={messageId:generateId(),messageBy:"client",message:messageText,name:User?.personal?.fullName,createdAt:new Date(),exactTime:(new Date()).getTime()};
          await addDoc(subCollectionRef,messageData);
          // alert("Message Sucessfully Send");
          toast({
            title: "Message sent successfully",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                  position: "top",
                  variant: "solid",
                  backgroundColor: "white",
                  containerStyle: {
                    backgroundColor: "white",
                    border: "2px solid #38A169",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    padding: "3px",
                    boxShadow: "lg",
                    color: "#2D3748",
                    boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
                  },
                });
        }
      }else{
        const docId=querySnapshot.docs[0].id;
        const subCollectionRef=collection(primaryDB,`Chats/${docId}/Messages`);
        const messageData={messageId:generateId(),messageBy:"client",message:messageText,name:User?.personal?.fullName,createdAt:new Date(),exactTime:(new Date()).getTime()};
        await addDoc(subCollectionRef,messageData);
        // alert("Message Sucessfully Send");
        toast({
          title: "Message sent successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
                variant: "solid",
                backgroundColor: "white",
                containerStyle: {
                  backgroundColor: "white",
                  border: "2px solid #38A169",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "3px",
                  boxShadow: "lg",
                  color: "#2D3748",
                  boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
                },
              });
      }
      setIsVisible(false);
    }catch(error){
      console.log(error);
      // alert('Message Sending Failed')
      toast({
        title: "Failed to send message",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
      }
  }

  return (
    <div className="flex w-full border-2 text-sm md:text-lg font-Cabin bg-[#f7fcfb] overflow-hidden rounded-[1rem] md:rounded-[1.7rem] z-10 flex-col">
      <div className="bg-[#1C6ED0] py-2 px-3 md:px-4 w-full flex justify-between items-center">
        <div className="flex gap-2">
          <img src={MessageImg} alt="MessageImg" width={'25rem'}/>
          <p className="font-bold  text-white">Chat Window</p>
        </div>
        <button
          className="rounded-[.6rem] bg-transparent text-white p-0 "
          onClick={() => setIsVisible(false)}
        >
          X
        </button>
      </div>
      <div className="w-full p-2 flex md:pb-3 md:p-4 flex-col ">

        <textarea
          name="chat"
          id="chat"
          rows={row}
          className="w-full resize-none border-2 rounded-[.5rem] p-2"
          placeholder="Type Your Message"
          onChange={handleTextChange}
        ></textarea>

        <button className="btn btn-primary ml-auto rounded-[.4rem] md:px-3 mt-3 btn-md " onClick={sendMessage}>
        {/* <Link to={'/'} >Send</Link> */}
        Send
        </button>
      </div>
    </div>
  );
};

export default ChatPopup;
