import React from "react";
import { useNavigate } from "react-router-dom";

const Heading = ({ name ,category}) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-between mt-16 md:mx-16 " >
        <div className="flex">
          <hr className="m-2  border-t-8 border-blue-600 my-4 w-6" />
          <h1 className="text-black font-[500] text-[18px] md:text-[35px]  " style={{
            fontFamily:"cabin"
          }}>
            {name}
          </h1>
        </div>
        <button  style={{borderRadius:"6px"}}className="hidden xl:flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 md:w-28 md:h-11 text-lg font-semibold transition duration-300 ease-in-out shadow-md border border-blue-600"
              onClick={() => navigate(`/resources/${category}`)}
        >
         See All
          </button>
      </div>
    </div>
  );
};

export default Heading;
