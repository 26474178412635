import NavBar from "../../Components/bookingDetails/navBar";
import WebBooking_Container from "../../Components/bookingDetails/WebBooking_Container";
import WebPm_summary2 from "../../Components/bookingDetails/WebPm_summary2";
import { useEffect, useState } from "react";
import "./BookingDetails.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { partnerDB } from "../../config/firebase";
import { useSearchParams,useParams } from "react-router-dom";
import useServicesStore from "./storePr";

function WebBookingDetails() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const { servicesData, setServicesData } = useServicesStore();
  const { bidID, serviceName, enquiryID } = useParams();
  const [BiddingData, setBiddingData] = useState([]);
  const [formData,setFormData]=useState({
    fullName:"",
    contactNumber:"",
    email:"",
    whatsappNo:"",
    invoiceName:"",
    GSTNumber:"",
    address:""
  });
  const [state, setState] = useState("About");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!id) return;

  //     setIsLoading(true); // Set loading state to true before fetching

  //     try {
  //       const q = query(
  //         collection(partnerDB, "newPrData"),
  //         where("id", "==", Number(id))
  //       );

  //       const querySnapshot = await getDocs(q);
  //       const filteredPartners = querySnapshot.docs.map((doc) => doc.data());
  //       console.log("filteredPartners data", filteredPartners);
  //       const matchingPartner = filteredPartners.map((partner) => {
  //         console.log(partner);
  //         const matchingPackages = Object.entries(partner.packages).filter(
  //           ([pkgKey, pkgData]) => {
  //             const replacedPackageName = pkgData.name.replace(/\+/g, " ");
  //             return replacedPackageName === index;
  //           }
  //         );

  //         // Return the partner with only the matching packages
  //         return {
  //           ...partner,
  //           packages: Object.fromEntries(matchingPackages), // Convert back to object form
  //         };
  //       }).filter((partner) => Object.keys(partner.packages).length > 0);
  //       console.log(matchingPartner);
  //       setServicesData(matchingPartner);
  //       console.log(servicesData);
  //       setIsLoading(false); // Set loading state to false after fetching
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //       setIsLoading(false); // Ensure loading state is reset on error
  //     }
  //   };

  //   fetchData();
  // }, [id, partnerDB]);

  // Conditional rendering based on loading state and servicesData
  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  const handleFormDataChange=(e)=>{
    const {name,value}=e.target;
    setFormData((prevData)=>({...prevData,[name]:value}))
  }

  const getData = async () => {
    try {
      const collectionRef = collection(partnerDB, "biddingData");
      const enquiryQuery = query(collectionRef, where("EnquiryDetails.enquiryID", "==", enquiryID));
      const querySnapshot = await getDocs(enquiryQuery);
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id;
        const partnerCollectionRef = collection(partnerDB, `biddingData/${docId}/partners`);
        console.log("bidId:", bidID);
        const partnerQuery = query(partnerCollectionRef, where("biddingDetails.bidID", "==", bidID));
        const partnerDoc = await getDocs(partnerQuery);
        const partnerData = partnerDoc.docs[0].data();
        console.log("partnerData:",partnerData)
        setBiddingData(partnerData);
        }
        setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(()=>{
    getData();
  },[])

  useEffect(()=>{
    console.log("formData:",formData);
  },[formData])

  useEffect(()=>{
    console.log("bidID:",bidID);
  },[bidID]);

  const displayName = "Abc";

  const middleIndex = 1;

  const firstPart = "A";
  const middleLetter = "B";
  const lastPart = "C";

  return (
    <>
      <div className="app">
        <div id="navBar">
          <NavBar />
        </div>

        <div id="head-section">
          <p>{"ABC"}</p>
        </div>
        <div className="sections">
          <div className="booking-section1">
            <WebBooking_Container
              biddingData={BiddingData}
              handleFormData={handleFormDataChange}
              index={middleIndex}
            />
          </div>
          <div className="booking-section2">
            <WebPm_summary2 index={middleIndex} data={BiddingData} enquiryID={enquiryID} serviceName={serviceName} bidID={bidID} bookingDetails={formData} />
          </div>
        </div>
      </div>
    </>
  );
}

export default WebBookingDetails;
