import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "../../Styles/AuthStyles/homepageStyle.scss";
import "../../Styles/Fixed/staticStyle.scss";
import "../../Styles/Fixed/fixedDesign.scss";
// import { BsArrowBarLeft, BsArrowBarRight, BsChatRight, BsChevronBarRight, BsChevronRight, BsFillStarFill, BsSearch } from 'react-icons/bs';
// import { ImQuotesLeft } from 'react-icons/im';
// import { RxCaretLeft, RxCaretRight, RxPinRight } from 'react-icons/rx';
import {
  // AskBigCard,
  // BlogsBigCard,
  BlogsBigCard2,
  // BlogsSmallCard,
  ContactUsDesign,
  // HowCard,
  // HowCardCircle,
  // HowCardRound,
  // IndustriesBigCard,
  // IndustriesSmallCard,
  // OurServicesCard,
  SubHeader,
  // TestimonialDesign,
  // WhyBestCard,
  // WhyUsCard,
} from "../../Components/Design/fixedDesigns";
// import { useState } from 'react';
// import Faq from "react-faq-component";
// import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { TestimonialsSlide } from '../../Components/Extra/extraFunction';
// import { NavLink } from 'react-router-dom';
import NavBar from "../../Components/Header/NavBar";
import { useMediaQuery } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import ApplyBodyStylesOnLoad from "../../Components/ApplyBodyStylesOnLoad ";
import SpecificBlog7_img1 from "./assets/blogImages/SpecificBlog7/SpecificBlog7_img1.jpg";
import SpecificBlog8_img1 from "./assets/blogImages/SpecificBlog8/SpecificBlog8_img1.jpg";
import SpecificBlog9_img1 from "./assets/blogImages/SpecificBlog9/specificBlog9_img1.jpg";

const Blogs = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const blogPosts = [
    {
      id: 1,
      icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1697129744/Bizowl/blog1_mjudxr.jpg",
      jobTitle: "Business Service",
      tag: "bs",
      urlTitle: "the-importance-of-an-end-to-end-business-service-aggregating-platform",
      title:
        "The Importance of an End-to-End Business Service Aggregating Platform",
      description:
        "In today's fast-paced business world, small business owners and startup entrepreneurs face numerous challenges...",
      date: "12-Oct-2023",
    },
    {
      id: 2,
      icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1721878655/bizowl-blog-2_di2op8.png",
      jobTitle: "Public Relations",
      tag: "pr",
      urlTitle: "21-benefits-of-press-release-distribution",
      title:
        "21 Benefits of Press Release Distribution: Elevate Your Brand's Visibility and Credibility",
      description:
        "Discover 21 powerful benefits of press release distribution that can elevate your brand's visibility and credibility. Learn how press releases can boost SEO, build trust, and drive traffic.",
      date: "24-Jul-2024",
    },
    {
      id: 3,
      icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1723669728/bizowl-blog-3_vwmmib.png",
      jobTitle: "Public Relations",
      tag: "pr",
      urlTitle: "best-press-release-distribution-services",
      title:
        "Finding the Best Press Release Distribution Services: A Comprehensive Guide",
      description:
        "Discover the best press release distribution services to boost your media coverage and online presence. Learn key factors to consider and how Bizowl simplifies the process.",
      date: "15-Aug-2024",
    },
    {
      id: 4,
      icon: "https://res.cloudinary.com/dwjzihwuq/image/upload/v1724100311/photo1_yntb2p.png",
      jobTitle: "Public Relations",
      tag: "pr",
      urlTitle: "top-benefits-of-using-an-aggregator-for-online-press-release-distribution",
      title:
        "Top Benefits of Using an Aggregator for Online Press Release Distribution",
      description:
        "Discover how using an aggregator for online press release distribution can streamline your PR efforts, increase reach, and boost brand visibility effectively.",
      date: "20-Aug-2024",
    },
    {
      id: 5,
      icon:"https://res.cloudinary.com/dcduojrhf/image/upload/v1731077653/Importance_of_business_COnsulatncy_rd58t2.png",
      jobTitle:"Business Consulting",
      tag: "bc",
      urlTitle: "pre-seed-to-seed-why-earlystage-startups-need-business-consulting-a-complete-guide",
      title: "Pre-seed to Seed: Why EarlyStage Startups Need Business Consulting (A Founder's Complete Guide)",
      description:"Remember learning to drive? You could've figured it out alone, but having an experienced instructor made you confident and competent faster, while helping you avoid costly mistakes.",
      date: "08-Oct-2024"
    },
    // {
    //   id: 6,
    //   icon:"https://res.cloudinary.com/dr1pmtefx/image/upload/v1697129744/Bizowl/blog1_mjudxr.jpg",
    //   jobTitle:"Business Service",
    //   tag: "bs",
    //   urlTitle: "the-importance-of-an-end-to-end-business-service-aggregating-platform",
    //   title: "The Importance of an End-to-End Business Service Aggregating Platform",
    //   description:"In today's fast-paced business world, small business owners and startup entrepreneurs face numerous challenges. The demands can be overwhelming, from validating an idea",
    //   date: "08-Oct-2024"
    // },
    {
      id: 7,
      icon:SpecificBlog7_img1,
      jobTitle:"Business Consulting",
      tag: "bc",
      urlTitle: "why-small-and-medium-enterprises-need-professional-consulting-services",
      title: "Why Small and Medium Enterprises Need  Professional Consulting Services",
      description:"In an era where business complexity intersects with unprecedented opportunities, small and medium enterprises (SMEs) face a unique set of challenges that can make or break their success story.",
      date: "08-Oct-2024"
    },
    {
      id: 8,
      icon:SpecificBlog8_img1,
      jobTitle:"Business Consulting",
      tag: "bs",
      urlTitle: "10-marketing-strategies-to-revive-a-stagnant-user-base",
      title: "10 Marketing Strategies to Revive a Stagnant User Base",
      description:"Imagine pouring your heart and soul into a business, only to watch your user base plateau. It's a scenario that keeps marketing leaders and startup founders awake at night—a creeping sense of stagnation...",
      date: "11-Dec-2024"
    },
    {
      id: 9,
      icon:SpecificBlog9_img1,
      jobTitle:"Guide",
      tag: "guide",
      urlTitle: "ultimate-guide-hiring-best-web-development-agencies",
      title: "Why Does Hiring The Best Web Development Agency Matter?",
      description:"For a company which has a small size, it may be easier to resort to Discourse and have a third-party...",
      date: "11-Dec-2024"
    },

  ];



  return (
    <>
      <NavBar />
      <ApplyBodyStylesOnLoad />
      <div className="homepageContainer">
        {SubHeader("Blogs", "resources/blogs", "home", "")}

        <div className="blogsSec"
       >
          <div className="BigTitleContainer">
            <div className="BigTitleText">
              <span className="BigTitleHighlightedText">Our </span>Latest Blogs
            </div>
            <div className="BigTitleContainerDecoration"></div>
          </div>
          <div className="blogsMenu fullHeight">
            <div className="industriesMenuBody fullWidth">
              <Row md={12}>
                {blogPosts.map((post) => (
                  <Col key={post.id} md={6} className="marginTop20">
                    <Link to={`/resources/blogs/${post.tag}/${post.urlTitle}`}>
                      {" "}
                      {/* Use Link component for each blog post */}
                      <BlogsBigCard2  
                        icon={post.icon}
                        jobTitle={post.jobTitle}
                        title={post.title}
                        description={post.description}
                        date={post.date}
                      />
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>

        <div className="contactUs">{ContactUsDesign()}</div>
      </div>
      <Footer />
    </>
  );
};
export default Blogs;