import { useParams } from "react-router-dom";
import SpecificBlogs from "./SpecificBlogs";
import SpecificBlog2 from "./SpecificBlog2";
import SpecificBlog3 from "./SpecificBlog3";
import SpecificBlog4 from "./SpecificBlog4";
import SpecificBlog5 from "./SpecificBlog5";
import SpecificBlog6 from "./SpecificBlog6";
import SpecificBlog7 from "./SpecificBlog7";
import SpecificBlog8 from "./SpecificBlog8";
import SpecificBlog9 from "./SpecificBlog9";

const BlogToDisplay = () => {
  const { type, id } = useParams();

  if (type === "pr" && id === "best-press-release-distribution-services") {
    return <SpecificBlog3 />;
  } else if (
    type === "pr" &&
    id ===
      "top-benefits-of-using-an-aggregator-for-online-press-release-distribution"
  ) {
    return <SpecificBlog4 />;
  } else if (type === "pr") {
    return <SpecificBlog2 />;
  } else if (type === "bc" && id === "pre-seed-to-seed-why-earlystage-startups-need-business-consulting-a-complete-guide") {
    return <SpecificBlog5 />;
  } else if (type === "bs" && id === "10-marketing-strategies-to-revive-a-stagnant-user-base") {
    return <SpecificBlog8 />
  } else if (type === "bc" && id === "why-small-and-medium-enterprises-need-professional-consulting-services") {
    return <SpecificBlog7 />
  } else if(type ==="bs" && id === "the-importance-of-an-end-to-end-business-service-aggregating-platform"){
    return <SpecificBlog6/>
  }else if(type ==="guide" && id === "ultimate-guide-hiring-best-web-development-agencies"){
    return <SpecificBlog9/>
  } else {
    return <SpecificBlogs />;
  }
};

export default BlogToDisplay;
