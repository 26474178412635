import './App.css';
import AuthNavigation from './Components/Navigation/AuthNavigation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect,useState,createContext } from 'react';
import { primaryDB } from './config/firebase';
import { collection,query,where,getDocs } from 'firebase/firestore';
import { ScrollToTop } from './Components/Extra/extraFunction';
import { ReactNotifications } from 'react-notifications-component'
import Preloader from './Components/preLoader/PreLoader';


export const userContext=createContext()

function App() {
  const [verifiedUser, setVerifiedUser] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const checkSession = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_AUTHENTICATION_LINK, {
        credentials: "include"
      });

      const data = await response.json();

      const userCollectionRef = collection(primaryDB, "userProfiles");
      const userQuery = query(userCollectionRef, where("uid", "==", data.user.uid));
      const querySnapshot = await getDocs(userQuery);
      
      if (!querySnapshot.empty) {
        console.log(querySnapshot.docs[0].data());
        setVerifiedUser(querySnapshot.docs[0].data());
      }
    } catch (error) {
      console.error("Error checking session:", error);
    } finally {
      setIsLoading(false); // Set loading to false when done
    }
  }
  
  useEffect(() => {
    AOS.init({ duration: 500 });
    checkSession();
  }, [])
  
  return (
    <div className="App">
      {isLoading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          backgroundColor: '#f8f9fa'
        }}>
          <Preloader type="circle" />
          <Preloader type="triangle" />
          <Preloader type="rectangle" />
        </div>
      ) : (
        <>
          <ReactNotifications/>
          <ScrollToTop/>
          <userContext.Provider value={verifiedUser}>
            <AuthNavigation/>
          </userContext.Provider>
        </>
      )}
    </div>
  );
}

export default App;

// import './App.css';
// import AuthNavigation from './Components/Navigation/AuthNavigation';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import { useEffect,useState,createContext } from 'react';
// import { primaryDB } from './config/firebase';
// import { collection,query,where,getDocs, QuerySnapshot } from 'firebase/firestore';
// import { ScrollToTop } from './Components/Extra/extraFunction';
// import { ReactNotifications } from 'react-notifications-component'

// export const userContext=createContext()

// function App() {
//   const [verifiedUser,setVerifiedUser]=useState();

//     const checkSession=async ()=>{
//       const response=await fetch(process.env.REACT_APP_AUTHENTICATION_LINK,{
//         credentials:"include"
//       });

//     const data=await response.json()

//     const userCollectionRef=collection(primaryDB,"userProfiles");
//     const userQuery=query(userCollectionRef,where("uid","==",data.user.uid));
//     const querySnapshot=await getDocs(userQuery);
//     if(!querySnapshot.empty){
//       console.log(querySnapshot.docs[0].data());
//       setVerifiedUser(querySnapshot.docs[0].data());
//     }    
//   }
  
//   useEffect(() => {
//     AOS.init({duration:500});
//     checkSession();
//   }, [])
  
//   return (
//     <div className="App">
//       <ReactNotifications/>
//       <ScrollToTop/>
      
//       <userContext.Provider value={verifiedUser}>
//         <AuthNavigation/>
//       </userContext.Provider>
//     </div>
//   );
// }

// export default App;
